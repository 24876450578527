import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Navbar from "../../components/navbar";
import styled from "styled-components";
import useGetClients from "../../hooks/useGetClients";
import useGetStaff from "../../hooks/useGetStaff";
import { Context } from "../../state";
import useGetAppointments from "../../hooks/useGetAppointments";
import { Pie } from "react-chartjs-2";

const Main = styled(Grid)``;

export default function Dashboard() {
  useGetClients();
  useGetStaff();
  useGetAppointments();
  const [state, dispatch] = useContext(Context);

  return (
    <Main container xs={12}>
      <Navbar />
      <Grid container item xs={10}>
        <div>
          <Pie
            options={{
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
            }}
            data={{
              labels: ["Oil Appointments", "Tires Appointments"],
              datasets: [
                {
                  label: "Dataset 1",
                  data: [
                    state.appointments.filter((apt) => {
                      const regex = /Oil/gi;
                      return regex.test(apt.serviceTypes);
                    }).length,
                    state.appointments.filter((apt) => {
                      const regex = /Tire/gi;
                      return regex.test(apt.serviceTypes);
                    }).length,
                  ],
                  backgroundColor: ["#eaff00", "#000000"],
                },
              ],
            }}
          />
        </div>
        <div>
          <Pie
            options={{
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
            }}
            data={{
              labels: [
                "New Oil Appointments",
                "Open Oil Appointments",
                "Closed Oil Appointments",
              ],
              datasets: [
                {
                  label: "Dataset 1",
                  data: [
                    state.appointments.filter((apt) => {
                      const regex = /Oil/gi;
                      return (
                        regex.test(apt.serviceTypes) && apt.status === "pending"
                      );
                    }).length,
                    state.appointments.filter((apt) => {
                      const regex = /Oil/gi;
                      return (
                        regex.test(apt.serviceTypes) &&
                        apt.status === "confirmed"
                      );
                    }).length,
                    state.appointments.filter((apt) => {
                      const regex = /Oil/gi;
                      return (
                        regex.test(apt.serviceTypes) && apt.status === "closed"
                      );
                    }).length,
                  ],
                  backgroundColor: ["#ff0000", "#ffff00", "#00ff00"],
                },
              ],
            }}
          />
        </div>
        <div>
          <Pie
            options={{
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
            }}
            data={{
              labels: [
                "New Tire Appointments",
                "Reviewed Tire Appointments",
                "Contacted Tire Appointments",
                "Won Tire Appointments",
                "Lost Tire Appointments",
              ],
              datasets: [
                {
                  label: "Dataset 1",
                  data: [
                    state.appointments.filter((apt) => {
                      const regex = /Tire/gi;
                      return (
                        regex.test(apt.serviceTypes) && apt.status === "pending"
                      );
                    }).length,
                    state.appointments.filter((apt) => {
                      const regex = /Tire/gi;
                      return (
                        regex.test(apt.serviceTypes) &&
                        apt.status === "reviewed"
                      );
                    }).length,
                    state.appointments.filter((apt) => {
                      const regex = /Tire/gi;
                      return (
                        regex.test(apt.serviceTypes) &&
                        apt.status === "contacted"
                      );
                    }).length,
                    state.appointments.filter((apt) => {
                      const regex = /Tire/gi;
                      return (
                        regex.test(apt.serviceTypes) && apt.status === "closed"
                      );
                    }).length,
                    state.appointments.filter((apt) => {
                      const regex = /Tire/gi;
                      return (
                        regex.test(apt.serviceTypes) && apt.status === "lost"
                      );
                    }).length,
                  ],
                  backgroundColor: [
                    "#7a5da8",
                    "#ffff00",
                    "#ffff00",
                    "#00ff00",
                    "#ff0000",
                  ],
                },
              ],
            }}
          />
        </div>
      </Grid>
    </Main>
  );
}
