import React, { useContext, useState } from "react";
import { Context } from "../../state";
import {
  Dialog,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import styled from "styled-components";
import apiRequest from "../../api";

const Overlay = styled(Dialog)`
  .MuiPaper-root {
    padding: 10px;
    width: 100%;
    max-width: 500px;
  }
  .overlay-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
`;

const NewStaffMemberForm = styled.form`
  display: flex;
  flex-direction: column;
  .MuiTextField-root {
    margin: 10px 0;
    width: 100%;
    input {
      height: 50px;
    }
  }
  .new-client-button {
    margin: 10px 0;
    height: 50px;
    background: rgba(var(--Swyft-Green));
    color: #fff;
  }
`;

const FlashError = styled(Alert)`
  margin: 20px 0 0;
`;

export default function NewStaffMemberOverlay({
  isOpen,
  setIsOpen,
  staffDisplay,
  setStaffDisplay,
}) {
  const [state, dispatch] = useContext(Context);
  const [flashError, setFlashError] = useState({
    isError: null,
    msg: "",
  });

  const handleInputChange = (e) =>
    dispatch({
      type: "NEW_EMPLOYEE_FORM",
      fieldName: e.target.name,
      payload: e.target.value,
    });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setFlashError({ isError: null, msg: "" });

    const { firstName, lastName, phoneNumber, email, permissions } =
      state.newEmployeeForm;

    if (!firstName || !firstName.trim())
      return setFlashError({
        isError: true,
        msg: "Please enter the employees first name.",
      });

    if (!lastName || !lastName.trim())
      return setFlashError({
        isError: true,
        msg: "Please enter the employees last name.",
      });

    if (!phoneNumber)
      return setFlashError({
        isError: true,
        msg: "Please enter the employees phone number.",
      });

    if (!email || !email.trim() || !email.includes("@") || !email.includes("."))
      return setFlashError({
        isError: true,
        msg: "Please enter a valid email address for the employee.",
      });

    if (!permissions > 0)
      return setFlashError({
        isError: true,
        msg: "Please select the employees role.",
      });

    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const newEmployeeResponce = await fetch(
      `${process.env.REACT_APP_API_URL}/employees`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ ...state.newEmployeeForm }),
      }
    ).then((res) => res.json());

    if (!newEmployeeResponce.success)
      return setFlashError({
        isError: true,
        msg: newEmployeeResponce.error.msg,
      });

    const employeeArray = [...staffDisplay];
    employeeArray.unshift(newEmployeeResponce.payload.employee);

    setStaffDisplay(employeeArray);
    dispatch({
      type: "ADD_STAFF_TO_LIST",
      payload: newEmployeeResponce.payload.employee,
    });

    dispatch({ type: "RESET_EMPLOYEE_FORM" });
    return setIsOpen(false);
  };

  return (
    <Overlay open={isOpen}>
      <div className="overlay-title-bar">
        <Typography variant="h4">New Employee</Typography>
        <Close onClick={() => setIsOpen(false)} />
      </div>
      {flashError.isError ? (
        <FlashError severity={flashError.isError ? "error" : "success"}>
          {flashError.msg}
        </FlashError>
      ) : null}
      <NewStaffMemberForm onSubmit={handleFormSubmit}>
        <TextField
          variant="outlined"
          label="First Name"
          placeholder="First Name"
          name="firstName"
          value={state.newEmployeeForm.firstName || ""}
          onChange={handleInputChange}
        />
        <TextField
          variant="outlined"
          label="Last Name"
          placeholder="Last Name"
          name="lastName"
          value={state.newEmployeeForm.lastName || ""}
          onChange={handleInputChange}
        />
        <TextField
          variant="outlined"
          label="Phone Number"
          placeholder="Phone Number"
          name="phoneNumber"
          value={state.newEmployeeForm.phoneNumber || ""}
          onChange={handleInputChange}
        />
        <TextField
          variant="outlined"
          label="Email"
          placeholder="Email Address"
          name="email"
          value={state.newEmployeeForm.email || ""}
          onChange={handleInputChange}
        />
        <InputLabel id="employee-role">Employee Role</InputLabel>
        <Select
          variant="outlined"
          labelId="employee-role"
          label="Employee Role"
          placeholder="Employee Role"
          name="permissions"
          value={state.newEmployeeForm.permissions || "not-selected"}
          onChange={handleInputChange}
        >
          <MenuItem value="not-selected" disabled>
            <Typography>Please Select Employees Role</Typography>
          </MenuItem>
          <MenuItem value="1">
            <Typography>Technician</Typography>
          </MenuItem>
          <MenuItem value="2">
            <Typography>Dispatch</Typography>
          </MenuItem>
          <MenuItem value="3">
            <Typography>Admin</Typography>
          </MenuItem>
        </Select>
        <Button className="new-client-button" type="submit">
          <Typography>Create New Employee</Typography>
        </Button>
      </NewStaffMemberForm>
    </Overlay>
  );
}
