import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../state";
import useGetStaff from "../../hooks/useGetStaff";
import styled from "styled-components";
import Navbar from "../../components/navbar";
import {
  Card,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Button,
  Dialog,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { addHours } from "date-fns/esm";
import PaymentOverlay from "../../components/paymentOverlay";
import Workorder from "../../components/workorder";
import WorkorderForm from "../../components/workorderForm";

const Main = styled(Grid)`
  width: 100%;
`;

const ContentSection = styled(Grid)`
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: column;
`;

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const AppointmentContainer = styled(Grid)`
  width: 100%;
`;

const SideBar = styled(Grid)`
  width: 100%;
`;

const ClientCard = styled(Card)`
  margin: 20px 10px;
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .appointment-info {
    width: 100%;
    padding: 0 10px;
    h6 {
      margin: 10px 0;
    }
    p {
      margin: 3px 0;
      padding: 0 0 0 10px;
    }
  }
`;

const ClientAvatar = styled.div`
  margin: 20px 0;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(props) => props.bgColor};
  color: #fff;
  h2 {
    font-weight: bolder;
  }
`;

const MainAppointmentInfoContainer = styled(Grid)`
  width: 100%;
`;

const MainAppointmentInfoCard = styled(Card)`
  margin: 20px 10px;
  padding: 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  h5,
  h6 {
    margin: 20px 0;
  }
  .MuiGrid-root {
    display: flex;
    flex-wrap: nowrap;
  }
  .MuiInputBase-root {
    margin: 10px;
    height: 50px;
  }
  .inspection_photos {
    padding: 10px;
    width: 100%;
    display: flex;
    overflow: scroll;
    img {
      padding: 10px;
      width: auto;
      height: 400px;
    }
  }
`;

const NotesInputs = styled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    margin: 10px;
    height: 150px;
  }
`;

const CustomeTextField = styled(TextField)`
  width: 100%;
`;

const ControlSection = styled(Grid)`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  max-width: 80vw;
`;

const WorkorderDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 20px;
  }
`;

export default function Appointment(props) {
  useGetStaff();
  const [appointment, setAppointment] = useState(null);
  const [avatarColor, setAvatarColor] = useState(null);
  const [selectedTech, setSelectedTech] = useState("Unassigned");
  const [paymentOverlayOpen, setPaymentOverlayOpen] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [isWorkorderDialogOpen, SetIsWorkorderDialogOpen] = useState(false);
  const [workorderVehicleId, setWorkorderVehicleId] = useState(null);
  const [vehicleOilPackage, setVehicleOilPackage] = useState(null);
  const { id } = props.match.params;

  const getAppointmentInfo = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const appointmentRequest = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments/${id}`,
      {
        method: "GET",
        headers: { Authorization: token },
      }
    ).then((res) => res.json());

    if (appointmentRequest.success)
      setAppointment(appointmentRequest.payload.appointment);

    if (appointmentRequest.payload.appointment.tech)
      setSelectedTech(appointmentRequest.payload.appointment.tech);
  };

  const assignTech = async (e) => {
    const techId = e.target.value;

    setSelectedTech(techId);

    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const assignTechResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/itineraries`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          techId,
          appointmentId: appointment._id,
        }),
      }
    ).then((res) => res.json());

    if (assignTechResponse.success) alert(`Appointment successfully assigned.`);
  };

  const saveAppointment = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const updateAppointmentResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...appointment,
        }),
      }
    ).then((res) => res.json());

    if (updateAppointmentResponse.success)
      alert("Appointment successfully updated.");
    else {
      alert(`Error: ${updateAppointmentResponse.errors[0].msg}`);
    }

    setAppointment({
      ...appointment,
      ...updateAppointmentResponse.payload.appointment,
    });
  };

  const confirmAppointment = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const confirmAppointmentResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...appointment,
          status: "confirmed",
        }),
      }
    ).then((res) => res.json());

    if (confirmAppointmentResponse.success)
      alert("Appointment successfully confirmed.");
    else {
      alert(`Error: ${confirmAppointmentResponse.error.msg}`);
    }

    setAppointment({
      ...appointment,
      ...confirmAppointmentResponse.payload.appointment,
    });
  };

  const PromptWorkorderMsg = (vehicleId, oilPackage) => {
    if (
      window.confirm(
        'You should only use this method of creating a workorder if you can not use the "Tech App" or experienced technical difficulties with the app. Do you wish to continue?'
      )
    ) {
      setWorkorderVehicleId(vehicleId);
      setVehicleOilPackage(oilPackage);
      return SetIsWorkorderDialogOpen(true);
    }
  };

  useEffect(() => {
    getAppointmentInfo();
    setAvatarColor(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
  }, []);

  if (!appointment) return null;

  return (
    <Main container item xs={12}>
      <PaymentOverlay
        appointment={appointment}
        open={paymentOverlayOpen}
        setIsOpen={setPaymentOverlayOpen}
      ></PaymentOverlay>
      <WorkorderDialog open={isWorkorderDialogOpen}>
        <WorkorderForm
          appointmentId={appointment._id}
          setIsEditMode={SetIsWorkorderDialogOpen}
          vehicleId={workorderVehicleId}
          oilPackage={vehicleOilPackage}
        />
      </WorkorderDialog>
      <Navbar />
      <ContentSection container item xs={10}>
        <SectionHeading>
          <Typography variant="h4">Appointment</Typography>
        </SectionHeading>
        <ControlSection></ControlSection>
        <AppointmentContainer container item>
          <SideBar container item xs={4}>
            <ClientCard>
              <Typography variant="h5">
                {appointment.user.firstName} {appointment.user.lastName}
              </Typography>
              <ClientAvatar bgColor={avatarColor}>
                <Typography variant="h2">
                  {appointment.user.firstName.charAt(0).toUpperCase()}
                </Typography>
              </ClientAvatar>
              <div className="appointment-info">
                <Typography variant="h6">Client Information</Typography>
                <Typography>
                  Phone Number: {appointment.user.phoneNumber}
                </Typography>
                <Typography>Email Address: {appointment.user.email}</Typography>
                <Typography variant="h6">Appointment Information</Typography>
                <Typography>
                  Appointment Status:{" "}
                  <Select
                    value={appointment.status}
                    onChange={(e) =>
                      setAppointment({ ...appointment, status: e.target.value })
                    }
                  >
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="confirmed">confirmed</MenuItem>
                    <MenuItem value="closed">closed</MenuItem>
                  </Select>
                </Typography>
                <Typography>
                  Assigned Technician:
                  <Select value={selectedTech} onChange={assignTech}>
                    <MenuItem disabled>Unassigned</MenuItem>
                    {state.staff.map((staffMember) => (
                      <MenuItem key={staffMember._id} value={staffMember._id}>
                        {staffMember.firstName} {staffMember.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </Typography>
                <Typography>Service: {appointment.serviceTypes}</Typography>
                <Typography>
                  Service Address: {appointment.address.formatted_address}
                </Typography>
                <Typography>
                  Appointment Date:{" "}
                  {new Date(appointment.timeWindow.start).toLocaleString()}
                </Typography>
                <Typography>
                  Time Windwow:{" "}
                  {new Date(appointment.timeWindow.start).getHours()} -{" "}
                  {new Date(appointment.timeWindow.end).getHours()}
                </Typography>
              </div>
            </ClientCard>
            {appointment.transaction ? (
              <ClientCard>
                <Typography variant="h5">Transaction</Typography>
                <div className="appointment-info">
                  <Typography>
                    <b>Transaction Status:</b> {appointment.transaction.status}
                  </Typography>
                  <Typography>
                    <b>Total:</b> $
                    {(
                      Math.floor(
                        appointment.transaction.cart.reduce(
                          (total, item) => (total += item.price),
                          0
                        )
                      ) / 100
                    ).toLocaleString({
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                  <Typography>
                    <b>Discount:</b> $
                    {(
                      Math.floor(appointment.transaction.discount) / 100
                    ).toLocaleString({
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                  <Typography>
                    <b>
                      Amount{" "}
                      {appointment.transaction.status !== "paid"
                        ? `Due:`
                        : `Paid:`}
                    </b>{" "}
                    $
                    {(
                      Math.floor(appointment.transaction.amount) / 100
                    ).toLocaleString({
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                  <Typography>
                    <b>Coupon Code:</b>{" "}
                    {appointment.transaction.coupon
                      ? appointment.transaction.coupon.code
                      : null}
                  </Typography>
                </div>
                {appointment.transaction.status !== "paid" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setPaymentOverlayOpen(true)}
                    fullWidth
                  >
                    Pay Now
                  </Button>
                ) : null}
              </ClientCard>
            ) : null}
          </SideBar>

          <MainAppointmentInfoContainer container item xs={8}>
            <MainAppointmentInfoCard>
              <FormControl>
                <Typography variant="h5">Client Information</Typography>
                <Grid container item xs={12}>
                  <CustomeTextField
                    variant="outlined"
                    placeholder="First Name"
                    value={appointment.user.firstName}
                    onChange={(e) =>
                      setAppointment({
                        ...appointment,
                        user: {
                          ...appointment.user,
                          firstName: e.target.value,
                        },
                      })
                    }
                  />
                  <CustomeTextField
                    variant="outlined"
                    placeholder="Last Name"
                    value={appointment.user.lastName}
                    onChange={(e) =>
                      setAppointment({
                        ...appointment,
                        user: {
                          ...appointment.user,
                          lastName: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid>
                  <CustomeTextField
                    variant="outlined"
                    placeholder="Email Address"
                    value={appointment.user.email}
                    onChange={(e) =>
                      setAppointment({
                        ...appointment,
                        user: {
                          ...appointment.user,
                          email: e.target.value,
                        },
                      })
                    }
                  />
                  <CustomeTextField
                    variant="outlined"
                    placeholder="Phone Number"
                    value={appointment.user.phoneNumber}
                    onChange={(e) =>
                      setAppointment({
                        ...appointment,
                        user: {
                          ...appointment.user,
                          phoneNumber: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Typography variant="h5">Appointment Information</Typography>
                <Grid container item xs={12}>
                  <CustomeTextField
                    variant="outlined"
                    placeholder="Service Address"
                    value={appointment.address.formatted_address}
                    onChange={(e) =>
                      setAppointment({
                        ...appointment,
                        address: {
                          ...appointment.address,
                          formatted_address: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid container item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={appointment.date}
                      onChange={(e) =>
                        setAppointment({
                          ...appointment,
                          date: e.toISOString(),
                          timeWindow: {
                            start: new Date(
                              new Date(e).setHours(
                                new Date(
                                  appointment.timeWindow.start
                                ).getHours(),
                                0,
                                0,
                                0
                              )
                            ),
                            end: new Date(
                              addHours(
                                new Date(e).setHours(
                                  new Date(
                                    appointment.timeWindow.start
                                  ).getHours(),
                                  0,
                                  0,
                                  0
                                ),
                                2
                              )
                            ),
                          },
                        })
                      }
                    />
                    <Select
                      variant="outlined"
                      value={new Date(appointment.timeWindow.start).getHours()}
                      onChange={(e) =>
                        setAppointment({
                          ...appointment,
                          timeWindow: {
                            start: new Date(
                              new Date(appointment.date).setHours(
                                parseInt(e.target.value),
                                0,
                                0,
                                0
                              )
                            ),
                            end: new Date(
                              addHours(
                                new Date(appointment.date).setHours(
                                  parseInt(e.target.value),
                                  0,
                                  0,
                                  0
                                ),
                                2
                              )
                            ),
                          },
                        })
                      }
                    >
                      <MenuItem value="8">
                        <Typography>8:00AM - 10:00AM</Typography>
                      </MenuItem>
                      <MenuItem value="10">
                        <Typography>10:00AM - 12:00PM</Typography>
                      </MenuItem>
                      <MenuItem value="12">
                        <Typography>12:00PM - 2:00PM</Typography>
                      </MenuItem>
                      <MenuItem value="14">
                        <Typography>2:00PM - 4:00PM</Typography>
                      </MenuItem>
                    </Select>
                  </MuiPickersUtilsProvider>
                </Grid>
                <NotesInputs
                  variant="outlined"
                  placeholder="Appointment Notes"
                  label="Appointment Notes"
                  name="notes"
                  onChange={(e) =>
                    setAppointment({
                      ...appointment,
                      [e.target.name]: e.target.value,
                    })
                  }
                  multiline={true}
                  value={appointment.notes}
                />
                {appointment.vehicles.map((vehicle, index) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5">
                        Vehicle #{index + 1} - {vehicle.oilPackage}
                      </Typography>
                      {appointment.status === "confirmed" &&
                      new Date(appointment.date) <= new Date() ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() =>
                            PromptWorkorderMsg(vehicle._id, vehicle.oilPackage)
                          }
                        >
                          Create Emergency Workorder
                        </Button>
                      ) : null}
                    </div>
                    <Grid container item xs={12}>
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Vin"
                        value={vehicle.vin}
                        onChange={(e) => {
                          const vehicles = [...appointment.vehicles];

                          vehicles[index].vin = e.target.value.toUpperCase();

                          setAppointment({
                            ...appointment,
                            vehicles,
                          });
                        }}
                      />
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Mileage"
                        value={vehicle.mileage}
                        onChange={(e) => {
                          const vehicles = [...appointment.vehicles];

                          vehicles[index].mileage = e.target.value;

                          setAppointment({
                            ...appointment,
                            vehicles,
                          });
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Year"
                        value={vehicle.year}
                        onChange={(e) => {
                          const vehicles = [...appointment.vehicles];

                          vehicles[index].year = e.target.value;

                          setAppointment({
                            ...appointment,
                            vehicles,
                          });
                        }}
                      />
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Make"
                        value={vehicle.make}
                        onChange={(e) => {
                          const vehicles = [...appointment.vehicles];

                          vehicles[index].make = e.target.value;

                          setAppointment({
                            ...appointment,
                            vehicles,
                          });
                        }}
                      />
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Model"
                        value={vehicle.model}
                        onChange={(e) => {
                          const vehicles = [...appointment.vehicles];

                          vehicles[index].model = e.target.value;

                          setAppointment({
                            ...appointment,
                            vehicles,
                          });
                        }}
                      />
                    </Grid>
                  </>
                ))}
                <Button onClick={confirmAppointment}>
                  <Typography color="primary">Confirm Appointment</Typography>
                </Button>
                <Button onClick={saveAppointment}>
                  <Typography color="primary">Save Appointment</Typography>
                </Button>
              </FormControl>
            </MainAppointmentInfoCard>
            {appointment.workorders.map((workorder) => (
              <Workorder workorderInfo={workorder} />
            ))}
          </MainAppointmentInfoContainer>
        </AppointmentContainer>
      </ContentSection>
    </Main>
  );
}
