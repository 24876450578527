import React, { useState, useContext, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import styled from "styled-components";
import {
  Dialog,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import { Context } from "../../state";
import VehicleInfoForm from "../vehicleInfoForm";
import NewClientOverlay from "../newClientOverlay";
import useGetLocations from "../../hooks/useGetLocations";

const Modal = styled(Dialog)`
  .MuiDialog-paper {
    padding: 10px;
    width: 100%;
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ModalContent = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  .section-header {
    display: flex;
    justify-content: space-between;
  }
  .multi-input-container {
    display: flex;
    div {
      width: 100%;
    }
  }
`;

export default function AppointmentOverlay() {
  useGetLocations();
  const [state, dispatch] = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientOverlayOpen, setClientOverlayOpen] = useState(false);
  const initialVehicleData = {
    year: "",
    make: "",
    model: "",
  };
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
    date: new Date(),
    timeWindow: 0,
    vehicles: [],
    locationId: 0,
    selectedItem: null,
    cart: [],
  });

  const handleClientSelection = (e, value) => {
    if (!value) return;

    const client = (({ firstName, lastName, email, phoneNumber }) => ({
      firstName,
      lastName,
      email,
      phoneNumber,
    }))(value);

    Object.keys(client).forEach((key) =>
      setFormData((prevState) => ({
        ...prevState,
        [key]: client[key],
      }))
    );
  };

  const handleChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const normalizeInput = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue;

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  const handleVehicleData = (e) => {
    const { index } = e.target.dataset;
    const { vehicles } = formData;

    vehicles[index][e.target.name] = e.target.value;

    setFormData({ ...formData, vehicles });
  };

  const addItemToCart = () => {
    const { selectedItem } = formData;
    const cart = [...formData.cart];

    if (cart.some((item) => item._id === selectedItem._id)) {
      const itemIndex = cart.map((item) => item._id).indexOf(selectedItem._id);
      cart[itemIndex].quanity = cart[itemIndex].quanity + 1;
    } else {
      cart.push({ ...selectedItem, quanity: 1 });
    }

    setFormData({ ...formData, cart });
  };

  useEffect(() => {
    setClients(state.clients);
  }, [state.clients]);

  return (
    <Modal open={isOpen}>
      <NewClientOverlay
        clientDisplay={[]}
        setClientDisplay={() => []}
        isOverlayOpen={clientOverlayOpen}
        setOverlayOpen={setClientOverlayOpen}
      />
      <ModalHeader>
        <Typography variant="h4">New Appointment</Typography>
        <span style={{ cursor: "pointer" }} onClick={() => setIsOpen(false)}>
          &times;
        </span>
      </ModalHeader>
      <ModalContent>
        <div className="section-header">
          <Typography variant="h5">Client Information</Typography>
          <Button
            variant="outlined"
            onClick={() => setClientOverlayOpen(!clientOverlayOpen)}
          >
            Create New Client
          </Button>
        </div>
        <Autocomplete
          options={clients}
          onChange={handleClientSelection}
          getOptionLabel={(client) => `${client.firstName} ${client.lastName}`}
          renderOption={(client) => `${client.firstName} ${client.lastName}`}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="outlined"
              placeholder="Select A Client..."
            />
          )}
        />
        <div className="multi-input-container">
          <TextField
            variant="outlined"
            placeholder="First Name"
            value={formData.firstName}
          />
          <TextField
            variant="outlined"
            placeholder="Last Name"
            value={formData.lastName}
          />
        </div>
        <div className="multi-input-container">
          <TextField
            variant="outlined"
            placeholder="Email Address"
            value={formData.email}
          />
          <TextField
            variant="outlined"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={(e) => {
              const value = normalizeInput(
                e.target.value,
                formData.phoneNumber
              );
              setFormData({
                ...formData,
                [e.target.name]: value,
              });
            }}
          />
        </div>
        <div className="section-header">
          <Typography variant="h5">Appointment Information</Typography>
        </div>
        <Select
          value={formData.locationId}
          onChange={handleChange}
          name="locationId"
        >
          <MenuItem value="0" disabled>
            <Typography>Please select a Swyft location</Typography>
          </MenuItem>
          {state.locations.map((location) => (
            <MenuItem key={location._id} value={location._id}>
              <Typography>
                {location.city}, {location.state}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        <TextField
          variant="outlined"
          placeholder="Street Address"
          name="streetAddress"
          value={formData.streetAddress}
          onChange={handleChange}
        />
        <div className="multi-input-container">
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            className="multi-input-container"
          >
            <DatePicker
              inputVariant="outlined"
              value={formData.date}
              onChange={(date) => setFormData({ ...formData, date })}
            />
          </MuiPickersUtilsProvider>
          <Select
            name="timeWindow"
            value={formData.timeWindow}
            onChange={handleChange}
          >
            <MenuItem value="0" disabled>
              <Typography>Please select a time window</Typography>
            </MenuItem>
            <MenuItem value="8">
              <Typography>8:00AM - 10:00AM</Typography>
            </MenuItem>
            <MenuItem value="10">
              <Typography>10:00AM - 12:00PM</Typography>
            </MenuItem>
            <MenuItem value="12">
              <Typography>12:00PM - 2:00PM</Typography>
            </MenuItem>
            <MenuItem value="14">
              <Typography>2:00PM - 4:00PM</Typography>
            </MenuItem>
          </Select>
        </div>
        <div className="section-header">
          <Typography variant="h5">Shopping Cart</Typography>
          <Typography variant="h5">$0.00</Typography>
        </div>
        <div>
          <Autocomplete
            disabled={!formData.locationId}
            options={state.locations
              .filter((location) => location._id === formData.locationId)[0]
              ?.services.filter((service) => !service.customizable)}
            getOptionLabel={(location) =>
              `${location.title} - $${(
                parseInt(location.price) / 100
              ).toLocaleString({ style: "currency", currency: "USD" })}`
            }
            renderOption={(location) => (
              <Typography>
                {location.title} - $
                {(parseInt(location.price) / 100).toLocaleString({
                  styled: "currency",
                  currency: "USD",
                })}
              </Typography>
            )}
            renderInput={(props) => (
              <TextField {...props} placeholder="Services" variant="outlined" />
            )}
            onChange={(e, data) =>
              setFormData({ ...formData, selectedItem: data })
            }
          />
          <Button onClick={addItemToCart}>Add To Cart</Button>
        </div>
        {formData.cart.map((item) => (
          <div>
            <Typography>
              {item.title} - x{item.quanity}
            </Typography>
            {item.metadata.map((metadata) => (
              <Typography>{metadata.title}</Typography>
            ))}
          </div>
        ))}
        <div className="section-header">
          <Typography variant="h5">Vehicle Information</Typography>
          <Button
            variant="outlined"
            onClick={() =>
              setFormData({
                ...formData,
                vehicles: [...formData.vehicles, initialVehicleData],
              })
            }
          >
            Add New Vehicle
          </Button>
        </div>
        {formData.vehicles.map((val, index) => (
          <VehicleInfoForm
            key={index}
            index={index}
            formData={formData}
            handleVehicleData={handleVehicleData}
          />
        ))}
      </ModalContent>
    </Modal>
  );
}
