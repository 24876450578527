import { useEffect, useContext } from "react";
import { Context } from "../state";
import apiRequest from "../api";

export default function useGetAppointments() {
  const [state, dispatch] = useContext(Context);

  const fetchAppoitmentList = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const appointmentResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments`,
      { method: "GET", headers: { Authorization: token } }
    ).then((res) => res.json());

    if (appointmentResponse.success)
      dispatch({
        type: "INIT_APPOINTMENT_LIST",
        payload: appointmentResponse.payload.appointments,
      });
  };

  useEffect(() => {
    fetchAppoitmentList();
  }, []);

  return null;
}
