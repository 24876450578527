import React from "react";
import {
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@material-ui/core";
export default function VehicleInfoForm({
  index,
  formData,
  handleVehicleData,
}) {
  return (
    <>
      <TextField
        placeholder="VIN"
        variant="outlined"
        name="vin"
        onChange={handleVehicleData}
        value={formData.vehicles[index].vin}
      />
      <div className="multi-input-container">
        <TextField
          placeholder="Year"
          variant="outlined"
          inputProps={{ "data-index": index }}
          name="year"
          onChange={handleVehicleData}
          value={formData.vehicles[index].year}
        />
        <TextField
          placeholder="Make"
          variant="outlined"
          inputProps={{ "data-index": index }}
          name="make"
          onChange={handleVehicleData}
          value={formData.vehicles[index].make}
        />
        <TextField
          placeholder="Model"
          variant="outlined"
          inputProps={{ "data-index": index }}
          name="model"
          onChange={handleVehicleData}
          value={formData.vehicles[index].model}
        />
      </div>
    </>
  );
}
