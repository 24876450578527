import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Button, Typography } from "@material-ui/core";
import {
  HomeRounded,
  PersonRounded,
  GroupRounded,
  AssignmentIndRounded,
  BuildRounded,
  Label,
} from "@material-ui/icons";
import styled from "styled-components";

const StyledNavbar = styled(Grid)`
  width: 100%;
  min-height: 100vh;
  background: rgba(var(--Swyft-Purple));
  flex-direction: column;
`;
const StyledButton = styled(Button)`
  padding: 15px 30px;
  justify-content: flex-start;
  font-weight: bold;
  color: #fff;
  span > p {
    font-weight: bold;
  }
`;

export default function Navbar() {
  const history = useHistory();
  return (
    <StyledNavbar item container xs={2}>
      <StyledButton
        startIcon={<HomeRounded />}
        onClick={() => history.push("/dashboard")}
      >
        <Typography>Dashboard</Typography>
      </StyledButton>
      <StyledButton
        startIcon={<PersonRounded />}
        onClick={() => history.push("/dashboard/clients")}
      >
        <Typography>Clients</Typography>
      </StyledButton>
      <StyledButton
        startIcon={<GroupRounded />}
        onClick={() => history.push("/dashboard/staff")}
      >
        <Typography>Staff Members</Typography>
      </StyledButton>
      <StyledButton
        startIcon={<AssignmentIndRounded />}
        onClick={() => history.push("/dashboard/appointments")}
      >
        <Typography>Oil Apts</Typography>
      </StyledButton>
      <StyledButton
        startIcon={<AssignmentIndRounded />}
        onClick={() => history.push("/dashboard/tire")}
      >
        <Typography>Tire Apts</Typography>
      </StyledButton>
      <StyledButton
        startIcon={<Label />}
        onClick={() => history.push("/dashboard/coupons")}
      >
        <Typography>Coupons</Typography>
      </StyledButton>
      <StyledButton
        startIcon={<Label />}
        onClick={() => history.push("/dashboard/deals")}
      >
        <Typography>Deals</Typography>
      </StyledButton>
    </StyledNavbar>
  );
}
