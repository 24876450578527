import {
  Button,
  Card,
  CardActions,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import WorkorderForm from "../workorderForm";

const WorkorderCard = styled(Card)`
  margin: 20px 10px;
  padding: 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  h5,
  h6 {
    margin: 20px 0;
  }
  .MuiGrid-root {
    display: flex;
    flex-wrap: nowrap;
  }
  .MuiInputBase-root {
    margin: 10px;
    height: 50px;
  }
  .inspection_photos {
    padding: 10px;
    width: 100%;
    display: flex;
    overflow: scroll;
    img {
      padding: 10px;
      width: auto;
      height: 400px;
    }
  }
`;

export default function Workorder({ workorderInfo }) {
  const [workorder, setWorkorder] = useState(workorderInfo);
  const [workorderPhotos, setWorkorderPhotos] = useState([]);

  const creationDate = new Date(workorder.createdAt);
  const editExperationDate = new Date(
    new Date(creationDate).setDate(new Date(creationDate).getDate() + 4)
  );

  const canEditWorkorder = new Date() < editExperationDate;

  const [isEditMode, setIsEditMode] = useState(false);

  const getInspectionPhotos = async () => {
    if (workorderPhotos.length > 0) return;

    const photosResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/inspections/${workorder.appointment}/${workorder.vehicle._id}`
    ).then((res) => res.json());

    if (!photosResponse.success) return;

    setWorkorderPhotos(photosResponse.payload.photos);
  };

  useEffect(() => {
    getInspectionPhotos();
  }, []);

  return (
    <WorkorderCard>
      {isEditMode ? (
        <WorkorderForm
          workorderInfo={workorder}
          setMasterWorkorder={setWorkorder}
          setIsEditMode={setIsEditMode}
        />
      ) : (
        <>
          <Typography variant="h5">
            {workorder.vehicle.year} {workorder.vehicle.make}{" "}
            {workorder.vehicle.model} - {workorder.vehicle.vin}
          </Typography>
          <Typography>
            <b>VIN: </b>
            {workorder.vehicle.vin}
          </Typography>
          <Typography>
            <b>Year: </b>
            {workorder.vehicle.year}
          </Typography>
          <Typography>
            <b>Make: </b>
            {workorder.vehicle.make}
          </Typography>
          <Typography>
            <b>Model: </b>
            {workorder.vehicle.model}
          </Typography>

          {workorder.vehicle.trim ? (
            <Typography>
              <b>Trim: </b>
              {workorder.vehicle.trim}
            </Typography>
          ) : null}

          <Typography>
            <b>Mileage: </b>
            {workorder.vehicle.mileage.toLocaleString()}
          </Typography>
          <Typography>
            <b>Engine Size: </b>
            {workorder.vehicle.engineSize}
          </Typography>
          <Typography>
            <b>Plate Number: </b>
            {workorder.vehicle.plateNumber}
          </Typography>
          <Typography>
            <b>Service: </b>
            {workorder.vehicle.oilPackage}
          </Typography>

          <Typography variant="h6">Tire Information</Typography>
          {workorder.evaluation.tires.map((tire) => (
            <Typography>
              <b>{tire.tireLocation} Tire: </b>
              {tire.psi}psi / {tire.treadDepth}mm
            </Typography>
          ))}

          {workorderPhotos.length > 0 ? (
            <>
              <Typography variant="h5">Inspection Photos</Typography>
              <div className="inspection_photos">
                {workorderPhotos
                  .filter((photo) => photo.vehicle === workorder.vehicle._id)
                  .map((photo) => (
                    <img src={photo.url} key={photo._id} alt="" />
                  ))}
              </div>
            </>
          ) : null}

          {canEditWorkorder ? (
            <CardActions>
              <Button onClick={() => setIsEditMode(!isEditMode)}>
                <Typography>Edit Workorder</Typography>
              </Button>
            </CardActions>
          ) : null}
        </>
      )}
    </WorkorderCard>
  );
}
