import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../state";

import { Grid, Typography, Select, MenuItem } from "@material-ui/core";
import Navbar from "../../components/navbar";
import apiRequest from "../../api";
import useGetAppointments from "../../hooks/useGetAppointments";

const TireInstallApts = styled(Grid)``;

const ContentSection = styled(Grid)`
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const CustomTabe = styled(Grid)`
  width: 100%;
`;

const TabelRow = styled(Grid)`
  width: 100%;
  padding: 10px 0;
  display: flex;
  color: #000;
  cursor: pointer;
  background: ${({ status }) =>
    status === "pending"
      ? `rgba(var(--Swyft-Purple))`
      : status === "reviewed" || status === "contacted"
      ? `rgba(255, 255, 0, 1)`
      : status === "closed"
      ? `rgba(var(--Swyft-Green))`
      : `rgba(255, 0, 0, 1)`};
  &:nth-child(1) {
    background: rgb(var(--Swyft-Purple));
    color: #fff;
  }
  &:nth-child(even) {
    opacity: 0.8;
  }
`;

const TabelCell = styled(Grid)`
  padding: 10px 5px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function TireInstallationApts() {
  useGetAppointments();
  const history = useHistory();
  const [tireInstallationApts, setTireInstallationApts] = useState([]);
  const [state, dispatch] = useContext(Context);
  const [filter, setFilter] = useState("all");

  // const getTireInstallationApts = async () => {
  //   const token = localStorage.getItem(
  //     process.env.REACT_APP_LOGIN_TOKEN
  //   )
  //   const installAptsResponse = await apiRequest(
  //     '/appointment/tire/installation/all',
  //     'GET',
  //     {
  //       Authorization: token,
  //     }
  //   )

  //   if (!installAptsResponse.success)
  //     return alert(installAptsResponse.error.msg)

  //   setTireInstallationApts([
  //     ...installAptsResponse.payload,
  //   ])
  // }

  const filterApts = (e) => {
    setFilter(e.target.value);
    if (e.target.value === "all")
      setTireInstallationApts(
        state.appointments.filter((appointment) =>
          appointment.serviceTypes.includes("Tire Services")
        )
      );
    else
      setTireInstallationApts(
        state.appointments.filter(
          (appointment) =>
            appointment.serviceTypes.includes("Tire Services") &&
            appointment.address.state === e.target.value
        )
      );
  };

  useEffect(() => {
    // getTireInstallationApts()
    setTireInstallationApts(
      state.appointments.filter((appointment) =>
        appointment.serviceTypes.includes("Tire Services")
      )
    );
  }, [state.appointments]);

  return (
    <TireInstallApts container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        <SectionHeading>
          <Typography variant="h4">Tire Installations</Typography>
        </SectionHeading>
        <Grid style={{ display: "flex" }}>
          <Typography>Sort/Filter: </Typography>
          <Select value={filter} onChange={filterApts}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="CO">CO</MenuItem>
            <MenuItem value="NV">NV</MenuItem>
          </Select>
        </Grid>
        <CustomTabe>
          <TabelRow>
            <TabelCell>
              <Typography>Status</Typography>
            </TabelCell>
            <TabelCell>
              <Typography>Clients Name</Typography>
            </TabelCell>
            <TabelCell>
              <Typography>Email</Typography>
            </TabelCell>
            <TabelCell>
              <Typography>Phone Number</Typography>
            </TabelCell>
            <TabelCell>
              <Typography>Zip Code</Typography>
            </TabelCell>
            <TabelCell>
              <Typography>Date</Typography>
            </TabelCell>
          </TabelRow>
          {tireInstallationApts.map((apt) => (
            <TabelRow
              onClick={() => history.push(`/dashboard/tire/install/${apt._id}`)}
              status={apt.status}
              key={apt._id}
            >
              <TabelCell>
                <Typography>{apt.status}</Typography>
              </TabelCell>
              <TabelCell>
                <Typography>
                  {apt.user.firstName} {apt.user.lastName}
                </Typography>
              </TabelCell>
              <TabelCell>
                <Typography>{apt.user.email}</Typography>
              </TabelCell>
              <TabelCell>
                <Typography>{apt.user.phoneNumber}</Typography>
              </TabelCell>
              <TabelCell>
                <Typography>{apt.address.zipCode}</Typography>
              </TabelCell>
              <TabelCell>
                <Typography>{new Date(apt.date).toLocaleString()}</Typography>
              </TabelCell>
            </TabelRow>
          ))}
        </CustomTabe>
      </ContentSection>
    </TireInstallApts>
  );
}
