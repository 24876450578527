import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

export const buildTableHeadColumns = (columnsArray) =>
  columnsArray.map((title) => ({
    id: String(title).toLowerCase(),
    text: title,
    sortActive: false,
    sortDirection: "desc",
  }));

export default function DealsTable({ tableHeadColumns = [], items = [] }) {
  const location = useLocation();
  const history = useHistory();

  const [tableColumns, setTableColumns] = useState(tableHeadColumns);
  const [tableData, setTableData] = useState(items);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSort = (index) => {
    const tableColumnsArray = [...tableColumns];

    tableColumnsArray[index] = {
      ...tableColumnsArray[index],
      sortActive: !tableColumnsArray[index].sortActive,
      sortDirection: tableColumnsArray[index].sortActive ? "dsec" : "asc",
    };

    setTableColumns(tableColumnsArray);
  };

  const handlePageChange = (e, page) => {
    setPage(page);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const handleRowClick = (id) => {
    history.push(`${location.pathname}/${id}`);
  };

  useEffect(() => {
    setTableColumns(tableHeadColumns);
  }, [tableHeadColumns]);

  useEffect(() => {
    setTableData(items);
  }, [items]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableColumns.map((column, index) => (
              <TableCell key={index}>
                <TableSortLabel
                  active={column.sortActive}
                  direction={column.sortDirection}
                  onClick={() => handleSort(index)}
                >
                  <Typography>{column.text}</Typography>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data) => (
              <TableRow
                style={{ cursor: "pointer" }}
                key={data._id}
                onClick={() => handleRowClick(data._id)}
              >
                <TableCell>
                  <Typography>{data.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {`${String(data.description).substring(0, 75)}...`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {new Date(data.startDate).toLocaleDateString()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {new Date(data.endDate).toLocaleDateString()}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{String(data.isActive)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{String(data.isVisable)}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          rowsPerPage={rowsPerPage}
          count={items.length}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPage}
        />
      </Table>
    </TableContainer>
  );
}
