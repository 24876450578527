import React, { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import apiRequest from "../../api";
import {
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
  CardActions,
  Button,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Context } from "../../state";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    .MuiFormControl-root {
      margin: 0.75rem 0;
    }
  }
`;

export default function CouponForm({ coupon, setCoupon, id, setIsOpen }) {
  const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);
  const history = useHistory();
  const [state, dispatch] = useContext(Context);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      ...coupon,
      discount: {
        ...coupon.discount,
        amount: coupon.discount.isFixedAmount
          ? coupon.discount.amount * 100
          : coupon.discount.amount / 100,
      },
    };

    let couponResponse;
    if (id)
      couponResponse = await apiRequest(
        `/coupons/${id}`,
        "PUT",
        {
          Authorization: token,
        },
        requestBody
      );
    else
      couponResponse = await apiRequest(
        `/coupons`,
        "POST",
        {
          Authorization: token,
        },
        requestBody
      );

    if (!couponResponse.success) return alert(couponResponse.error.msg);

    if (id) history.push("/dashboard/coupons");
    else {
      setIsOpen(false);
      return dispatch({
        type: "ADD_COUPON",
        payload: couponResponse.payload.coupon,
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "amount")
      return setCoupon({
        ...coupon,
        discount: {
          ...coupon.discount,
          [e.target.name]: e.target.value,
        },
      });

    return setCoupon({ ...coupon, [e.target.name]: e.target.value });
  };

  const handleBlur = (e) => {
    let num;

    if (coupon.discount.isFixedAmount)
      num = parseFloat(e.target.value || 0).toFixed(2);
    else num = parseFloat(e.target.value || 0).toFixed(0);

    setCoupon({
      ...coupon,
      discount: { ...coupon.discount, amount: num },
    });
  };

  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this coupon?")) return;

    const deleteCoupon = await apiRequest(`/coupons/${id}`, "DELETE", {
      Authorization: token,
    });

    if (!deleteCoupon.success) return;

    return history.push("/dashboard/coupons");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <CardContent>
        <div>
          <FormControlLabel
            control={<Switch checked={!coupon.discount.isFixedAmount} />}
            label="Is Percentage"
            onChange={() =>
              setCoupon({
                ...coupon,
                discount: {
                  ...coupon.discount,
                  isFixedAmount: !coupon.discount.isFixedAmount,
                },
              })
            }
          />
          <FormControlLabel
            control={<Switch checked={coupon.isOneTimeUse} />}
            label="Is One Time Use"
            onChange={() =>
              setCoupon({ ...coupon, isOneTimeUse: !coupon.isOneTimeUse })
            }
          />
          <FormControlLabel
            control={<Switch checked={coupon.isActive} />}
            label="Is Active"
            onChange={() =>
              setCoupon({ ...coupon, isActive: !coupon.isActive })
            }
          />
        </div>
        <TextField
          variant="outlined"
          placeholder="Code"
          id="code"
          name="code"
          label="Code"
          value={coupon.code}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          placeholder={coupon.discount.isFixedAmount ? `Amount` : `Percent`}
          id="amount"
          name="amount"
          label={coupon.discount.isFixedAmount ? `Amount` : `Percent`}
          value={coupon.discount.amount}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          variant="outlined"
          placeholder="Source"
          id="source"
          name="source"
          label="Source"
          value={coupon.source}
          onChange={handleChange}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            name="expirationDate"
            value={coupon.expirationDate}
            label="Expiration Date"
            inputVariant="outlined"
            onChange={(date) => setCoupon({ ...coupon, expirationDate: date })}
          />
        </MuiPickersUtilsProvider>
      </CardContent>
      <CardActions>
        {id ? (
          <>
            <Button type="submit">Update</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </>
        ) : (
          <>
            <Button type="submit">Create Coupon</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </>
        )}
      </CardActions>
    </Form>
  );
}
