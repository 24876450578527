import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Navbar from "../navbar";

const Main = styled(Grid)``;

const ContentSection = styled(Grid)`
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: column;
`;

export default function Layout({ children }) {
  return (
    <Main container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        {children}
      </ContentSection>
    </Main>
  );
}
