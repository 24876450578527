import { useEffect, useContext } from "react";
import { Context } from "../state/";

export default function useGetLocations() {
  const [state, dispatch] = useContext(Context);

  const fetchLocations = () =>
    fetch(`${process.env.REACT_APP_API_URL}/locations`)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "INIT_LOCATION_LIST",
          payload: data.payload.locations,
        });
      });

  useEffect(() => {
    fetchLocations();
  }, []);
}
