import {
  Button,
  Card,
  DialogTitle,
  IconButton,
  OutlinedInput,
  Table,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import SectionHeading from "../../components/sectionHeading";
import { Add, Search } from "@material-ui/icons";
import DealsTable, { buildTableHeadColumns } from "../../components/dealsTable";
import ControllSection from "../../components/controllSection";
import { Dialog } from "@mui/material";
import DealForm from "../../components/dealForm";

export default function DealsPage() {
  const tableHeadColumns = buildTableHeadColumns([
    "Title",
    "Description",
    "Start Date",
    "End Date",
    "Active",
    "Visable",
  ]);

  const [deals, setDeals] = useState([]);
  const [newDealOpen, setNewDealOpen] = useState(false);

  const getDeals = async () => {
    const dealsResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/deals`
    ).then((res) => res.json());

    if (!dealsResponse.success) return;

    setDeals(dealsResponse.payload.deals);
  };

  useEffect(() => {
    getDeals();
  }, []);

  return (
    <Layout>
      <SectionHeading text="Deals" />
      <ControllSection>
        <Button
          className="p-5"
          variant="outlined"
          endIcon={<Add />}
          onClick={() => setNewDealOpen(!newDealOpen)}
        >
          <Typography>New Deal</Typography>
        </Button>
        <OutlinedInput
          placeholder="Search"
          label="Search"
          endAdornment={
            <IconButton size="small">
              <Search />
            </IconButton>
          }
        />
      </ControllSection>
      <DealsTable tableHeadColumns={tableHeadColumns} items={deals} />
      <Dialog open={newDealOpen}>
        <DialogTitle>Create New Deal</DialogTitle>
        <DealForm cancelBtn={() => setNewDealOpen(false)} />
      </Dialog>
    </Layout>
  );
}
