import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../state";
import apiRequest from "../../api";
import {
  Button,
  Dialog,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import styled from "styled-components";
import { Alert, AlertTitle } from "@material-ui/lab";

const FlashError = styled(Alert)`
  margin: 20px 0 0;
`;

const Overlay = styled(Dialog)`
  .MuiPaper-root {
    padding: 10px;
    width: 100%;
    max-width: 800px;
  }
  .overlay-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
`;

const NewClientForm = styled.form`
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  .MuiTextField-root {
    margin: 10px 0;
    width: 100%;
    input {
      height: 50px;
    }
  }
  .multi-input-container {
    display: flex;
    justify-content: space-between;
    .MuiTextField-root {
      width: 49%;
    }
  }
  .street-address-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiTextField-root {
      width: 75%;
    }
    .MuiButton-root {
      width: 24%;
      height: 50px;
      background: rgba(var(--Swyft-Green));
      color: #fff;
    }
  }
  .vehicle-information-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .MuiInputBase-root {
      margin: 10px 0;
      width: 32%;
    }
  }
  .save-client-button {
    margin: 10px 0;
    height: 50px;
    background: rgba(var(--Swyft-Green));
    color: #fff;
  }
  .create-apt-button {
    height: 50px;
    background: rgba(var(--Swyft-Purple));
    color: #fff;
    justify-self: flex-end;
  }
`;

export default function NewClientOverlay(props) {
  const [state, dispatch] = useContext(Context);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [flashError, setFlashError] = useState({
    isError: null,
    title: "",
    msg: "",
  });
  const currentYear = new Date().getFullYear() + 1;
  const [isAddressVerified, setIsAddressVerified] = useState(false);
  const { clientDisplay, setClientDisplay, isOverlayOpen, setOverlayOpen } =
    props;

  const handelOverlayClose = () => setOverlayOpen(false);

  const handelInputChange = (e) =>
    dispatch({
      type: "NEW_CLIENT_FORM",
      fieldName: e.target.name,
      payload: e.target.value,
    });

  const getVehicleMakes = async () => {
    const vehicleMakeResponse = await fetch(
      "https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/MPV?format=json"
    ).then((res) => res.json());

    if (vehicleMakeResponse.Results.length > 0)
      setVehicleMakes(
        vehicleMakeResponse.Results.sort((a, b) => {
          if (a.MakeName > b.MakeName) return 1;
          if (a.MakeName < b.MakeName) return -1;
          return 0;
        })
      );
  };

  const getVehicleModels = async (makeId) => {
    const vehicleModelResponse = await fetch(
      `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeIdYear/makeId/${makeId}/modelyear/${state.newClientForm.vehicleYear}?format=json`
    ).then((res) => res.json());

    if (vehicleModelResponse.Results.length > 0)
      setVehicleModels(
        vehicleModelResponse.Results.sort((a, b) => {
          if (a.Model_Name > b.Model_Name) return 1;
          if (a.Model_Name < b.Model_Name) return -1;
          return 0;
        })
      );
  };

  const handelSaveClient = async () => {
    const {
      firstName,
      lastName,
      address,
      email,
      phoneNumber,
      vehicleYear,
      vehicleMake,
      vehicleModel,
    } = state.newClientForm;
    setFlashError({ isError: null, title: "", msg: "" });

    if (!firstName || firstName.trim() === "")
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: `Please enter the client's first name.`,
      });

    if (!lastName || lastName.trim() === "")
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: `Please enter the client's last name.`,
      });

    if (!address || address.trim() === "")
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: `Please enter the client's street address.`,
      });

    if (!email || email.trim() === "")
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: `Please enter the client's email address.`,
      });

    if (!phoneNumber || phoneNumber.trim() === "")
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: `Please enter the client's phone number.`,
      });

    if (!vehicleYear || !vehicleYear > 0)
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: `Please select the client's vehicle year.`,
      });

    if (!vehicleMake || vehicleMake.trim() === "")
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: `Please select the client's vehicle make.`,
      });

    if (!vehicleModel || vehicleModel.trim() === "")
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: `Please select the client's vehicle model.`,
      });

    const token = await localStorage.getItem("@DispatchLogin");
    const newClientResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/clients`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          address,
          email,
          phoneNumber,
          vehicleYear,
          vehicleMake,
          vehicleModel,
        }),
      }
    ).then((res) => res.json());

    if (!newClientResponse.success)
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: newClientResponse.error.msg,
      });

    const newClientArray = [...clientDisplay];
    newClientArray.unshift(newClientResponse.payload.client);
    if (newClientResponse.success) setClientDisplay(newClientArray);

    dispatch({
      type: "ADD_CLIENT_TO_LIST",
      payload: newClientResponse.payload.client,
    });

    dispatch({ type: "RESET_CLIENT_FORM" });
    return setOverlayOpen(false);
  };

  const verifyAddress = async () => {
    const { address } = state.newClientForm;

    if (!address || address.trim() === "")
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: "Please enter the clients service address.",
      });

    const addressVerificationResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/locations/validate/address`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ address }),
      }
    ).then((res) => res.json());

    if (!addressVerificationResponse.success)
      return setFlashError({
        isError: true,
        title: "Client Error",
        msg: addressVerificationResponse.error.msg,
      });

    dispatch({
      type: "NEW_CLIENT_FORM",
      fieldName: "address",
      payload: addressVerificationResponse.payload.address.formatted_address,
    });
    setIsAddressVerified(true);
    return setFlashError({
      isError: false,
      title: "Address Verification",
      msg: "Swyft offers service in the clients area.",
    });
  };

  useEffect(() => {
    getVehicleMakes();
  }, []);

  return (
    <Overlay open={isOverlayOpen}>
      <div className="overlay-title-bar">
        <Typography variant="h4">New Client</Typography>
        <Close onClick={handelOverlayClose} />
      </div>
      {flashError.isError !== null ? (
        flashError.isError ? (
          <FlashError severity="error">
            <AlertTitle>{flashError.title}</AlertTitle>
            {flashError.msg}
          </FlashError>
        ) : (
          <FlashError severity="success">
            <AlertTitle>{flashError.title}</AlertTitle>
            {flashError.msg}
          </FlashError>
        )
      ) : null}
      <NewClientForm>
        <div className="multi-input-container">
          <TextField
            variant="outlined"
            placeholder="First Name"
            label="First Name"
            name="firstName"
            onChange={handelInputChange}
            value={state.newClientForm.firstName}
          />
          <TextField
            variant="outlined"
            placeholder="Last Name"
            label="Last Name"
            name="lastName"
            onChange={handelInputChange}
            value={state.newClientForm.lastName}
            disabled={!state.newClientForm.firstName}
          />
        </div>
        <div className="street-address-container">
          <TextField
            variant="outlined"
            placeholder="Street Address"
            label="Street Address"
            name="address"
            onChange={handelInputChange}
            value={state.newClientForm.address}
            disabled={!state.newClientForm.lastName}
          />
          <Button
            onClick={verifyAddress}
            disabled={!state.newClientForm.address}
          >
            <Typography>Verify Address</Typography>
          </Button>
        </div>
        <div className="multi-input-container">
          <TextField
            variant="outlined"
            placeholder="Email Address"
            label="Email Address"
            name="email"
            onChange={handelInputChange}
            value={state.newClientForm.email}
            disabled={!isAddressVerified}
          />
          <TextField
            variant="outlined"
            placeholder="Phone Number"
            label="Phone Number"
            name="phoneNumber"
            onChange={handelInputChange}
            value={state.newClientForm.phoneNumber}
            disabled={!state.newClientForm.email}
          />
        </div>
        <div className="vehicle-information-container">
          <Select
            variant="outlined"
            label="Vehicle Year"
            displayEmpty
            name="vehicleYear"
            onChange={handelInputChange}
            value={state.newClientForm.vehicleYear || ""}
            disabled={!state.newClientForm.phoneNumber}
          >
            <MenuItem value="" disabled>
              <Typography>Vehicle Year</Typography>
            </MenuItem>
            {Array.from(Array(100), (value, index) => (
              <MenuItem value={currentYear - index} key={index}>
                <Typography>{currentYear - index}</Typography>
              </MenuItem>
            ))}
          </Select>
          <Select
            variant="outlined"
            label="Vehicle Make"
            displayEmpty
            name="vehicleMake"
            onChange={(e) => {
              handelInputChange(e);
            }}
            value={state.newClientForm.vehicleMake || ""}
            disabled={!state.newClientForm.vehicleYear}
          >
            <MenuItem value="" disabled>
              <Typography>Vehicle Make</Typography>
            </MenuItem>
            {vehicleMakes.map((x) => (
              <MenuItem
                value={x.MakeName}
                onClick={() => getVehicleModels(x.MakeId)}
                key={x.MakeId}
              >
                <Typography>{x.MakeName}</Typography>
              </MenuItem>
            ))}
          </Select>
          <Select
            variant="outlined"
            label="Vehicle Model"
            displayEmpty
            name="vehicleModel"
            onChange={handelInputChange}
            value={state.newClientForm.vehicleModel || ""}
            disabled={!state.newClientForm.vehicleMake}
          >
            <MenuItem value="" disabled>
              <Typography>Vehicle Model</Typography>
            </MenuItem>
            {vehicleModels.map((x) => (
              <MenuItem value={x.Model_Name} key={x.Model_Name}>
                <Typography>{x.Model_Name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </div>
        <Button className="save-client-button" onClick={handelSaveClient}>
          <Typography>Save Client</Typography>
        </Button>
        {/*
        <Button className="create-apt-button">
          <Typography>Create New Appointment</Typography>
        </Button>
        */}
      </NewClientForm>
    </Overlay>
  );
}
