import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'
import Navbar from '../../components/navbar'

const Main = styled(Grid)``

const ContentSection = styled(Grid)`
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05),
    0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`

const Table = styled(Grid)`
  width: 100%;
`

const TableRow = styled(Grid)`
  width: 100%;
  padding: 10px 0;
  display: flex;
  color: #000;
  cursor: pointer;
  background: ${({ status }) =>
    status === 'pending'
      ? `rgba(255, 0, 0, 1)`
      : status === 'confirmed'
      ? `rgba(255, 255, 0, 1)`
      : `rgba(var(--Swyft-Green))`};
  &:nth-child(1) {
    background: rgb(var(--Swyft-Purple));
    color: #fff;
  }
  &:nth-child(even) {
    opacity: 0.8;
  }
`

const TableCell = styled(Grid)`
  padding: 10px 5px;
  width: 25%;
  display: flex;
  flex-direction: column;
`

export default function Client({
  match: {
    params: { id },
  },
}) {
  const [appointments, setAppointments] = useState([])
  const history = useHistory()

  const getAppointments = async (id) =>
    await fetch(
      `${process.env.REACT_APP_API_URL}/clients/${id}/appointments`,
      {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem(
            process.env.REACT_APP_LOGIN_TOKEN
          ),
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) return
        setAppointments([...data.payload.appointments])
      })

  useEffect(() => {
    getAppointments(id)
  }, [])

  if (!appointments) return

  return (
    <Main container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        <SectionHeading>
          <Typography variant="h4">
            {appointments[0]?.user?.firstName}{' '}
            {appointments[0]?.user?.lastName}
          </Typography>
        </SectionHeading>
        <Table>
          <TableRow>
            <TableCell>
              <Typography>Status</Typography>
            </TableCell>
            <TableCell>
              <Typography>Service Type</Typography>
            </TableCell>
            <TableCell>
              <Typography>Year/Make/Model</Typography>
            </TableCell>
            <TableCell>
              <Typography>VIN</Typography>
            </TableCell>
            <TableCell>
              <Typography>Mileage</Typography>
            </TableCell>
            <TableCell>
              <Typography>Date</Typography>
            </TableCell>
          </TableRow>
          {appointments.map((appointment) => (
            <TableRow
              onClick={() =>
                history.push(
                  `/dashboard/${
                    appointment.serviceTypes[0].toLowerCase() ===
                    'oil'
                      ? 'appointments'
                      : appointment.serviceTypes[0].toLowerCase() ===
                        'tire sales'
                      ? 'tire'
                      : 'tire/install'
                  }/${appointment._id}`
                )
              }
              status={appointment.status}
            >
              <TableCell>
                <Typography>
                  {appointment.status}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {appointment.serviceTypes}
                </Typography>
              </TableCell>
              <TableCell>
                {appointment.workorders.length > 0
                  ? appointment.workorders.map(
                      ({ vehicle }) => (
                        <Typography>
                          {vehicle.year} {vehicle.make}{' '}
                          {vehicle.model}
                        </Typography>
                      )
                    )
                  : appointment.vehicles.map((vehicle) => (
                      <Typography>
                        {vehicle.year} {vehicle.make}{' '}
                        {vehicle.model}
                      </Typography>
                    ))}
              </TableCell>
              <TableCell>
                {appointment.workorders.length > 0
                  ? appointment.workorders.map(
                      ({ vehicle }) => (
                        <Typography>
                          {vehicle.vin}
                        </Typography>
                      )
                    )
                  : appointment.vehicles.map((vehicle) => (
                      <Typography>{vehicle.vin}</Typography>
                    ))}
              </TableCell>
              <TableCell>
                {appointment.workorders.length > 0
                  ? appointment.workorders.map(
                      ({ vehicle }) => (
                        <Typography>
                          {parseInt(
                            vehicle.mileage
                          ).toLocaleString()}
                        </Typography>
                      )
                    )
                  : appointment.vehicles.map((vehicle) => (
                      <Typography>
                        {parseInt(
                          vehicle.mileage
                        ).toLocaleString()}
                      </Typography>
                    ))}
              </TableCell>
              <TableCell>
                <Typography>
                  {new Date(
                    appointment.date
                  ).toLocaleDateString()}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </ContentSection>
    </Main>
  )
}
