import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Card,
  Button,
  Typography,
  TextField,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import styled from 'styled-components'
import useValidateUser from '../../hooks/useValidateUser'

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoginCard = styled(Card)`
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
`

const LoginInput = styled(TextField)`
  margin: 10px 0 0 !important;
`

const LoginButton = styled(Button)`
  margin: 10px 0 0 !important;
  background: rgb(122, 93, 168) !important;
  color: #fff !important;
`

const FlashError = styled(Alert)`
  margin: 10px 0 0 !important;
`

export default function Login() {
  const history = useHistory()
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const { loading, isAuth_ed } = useValidateUser()

  const initFlashErrorsObj = {
    isError: null,
    title: '',
    msg: '',
  }
  const [flashErrors, setFlashErrors] = useState(
    initFlashErrorsObj
  )

  const handelLogin = async () => {
    setFlashErrors(initFlashErrorsObj)

    if (!loginEmail || loginEmail.trim() === '')
      return setFlashErrors({
        isError: true,
        title: 'Login Error',
        msg: 'Please enter your email address.',
      })

    if (
      !loginEmail.includes('@') ||
      !loginEmail.includes('.')
    )
      return setFlashErrors({
        isError: true,
        title: 'Login Error',
        msg: 'Please enter a valid email address.',
      })

    if (!loginPassword || loginPassword.trim() === '')
      return setFlashErrors({
        isError: true,
        title: 'Login Error',
        msg: 'Please enter your password.',
      })

    if (loginPassword.length < 6)
      return setFlashErrors({
        isError: true,
        title: 'Login Error',
        msg: 'Your password must be at least 6 characters.',
      })

    const loginResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: loginEmail,
          password: loginPassword,
        }),
      }
    ).then((res) => res.json())

    if (!loginResponse.success)
      return setFlashErrors({
        isError: true,
        title: 'Login Error',
        msg: loginResponse.error.msg,
      })

    if (!loginResponse.payload.user.permissions.level > 0)
      return setFlashErrors({
        isError: true,
        title: 'Permission Error',
        msg:
          'You do not have permission to access this application.',
      })

    localStorage.setItem(
      process.env.REACT_APP_LOGIN_TOKEN,
      `Bearer ${loginResponse.payload.token}`
    )

    return history.push('/dashboard')
  }

  useEffect(() => {
    if (!loading && isAuth_ed)
      return history.push('/dashboard')
  }, [loading])

  return (
    <Main>
      <LoginCard>
        <Typography variant="h5">
          Swyft Dispatch Dashboard Login
        </Typography>
        {flashErrors.isError !== null ? (
          flashErrors.isError ? (
            <FlashError severity="error">
              <AlertTitle>{flashErrors.title}</AlertTitle>
              {flashErrors.msg}
            </FlashError>
          ) : (
            <FlashError severity="success">
              <AlertTitle>{flashErrors.title}</AlertTitle>
              {flashErrors.msg}
            </FlashError>
          )
        ) : null}
        <LoginInput
          variant="outlined"
          type="text"
          placeholder="Your Email"
          name="email"
          onChange={(e) => setLoginEmail(e.target.value)}
          value={loginEmail}
        />
        <LoginInput
          variant="outlined"
          type="password"
          placeholder="Your Password"
          name="password"
          onChange={(e) => setLoginPassword(e.target.value)}
          value={loginPassword}
        />
        <LoginButton
          variant="contained"
          onClick={handelLogin}
        >
          <Typography>Login</Typography>
        </LoginButton>
      </LoginCard>
    </Main>
  )
}
