import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import {
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  TextareaAutosize,
  Card,
  Button,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Navbar from "../../components/navbar";
import apiRequest from "../../api";
import DateFnsUtils from "@date-io/date-fns";
import { Context } from "../../state";
import useGetAppointments from "../../hooks/useGetAppointments";

const InstallAptContainer = styled(Grid)``;

const ContentSection = styled(Grid)`
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const FormCard = styled(Card)`
  margin: 20px;
  padding: 20px 10px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  .MuiInputBase-root {
    height: 50px;
  }
  .multi-input {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    .MuiFormControl-root {
      width: 48%;
    }
  }
  .address-input {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    .MuiFormControl-root:nth-child(1) {
      width: 74%;
    }
    .MuiFormControl-root:nth-child(2) {
      width: 24%;
    }
  }
  .textarea-notes {
    height: 150px !important;
  }
`;

const Input = styled(TextField)`
  width: 100%;
`;

const CustomSelect = styled(Select)`
  width: 48%;
`;

export default function TireInstallApt(props) {
  useGetAppointments();
  const [tireInstall, setTireInstall] = useState(null);
  const [state, dispatch] = useContext(Context);

  const { id } = props.match.params;
  const getTireAppointment = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const appointmentRequest = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments/${id}`,
      {
        method: "GET",
        headers: { Authorization: token },
      }
    ).then((res) => res.json());

    if (appointmentRequest.success)
      setTireInstall(appointmentRequest.payload.appointment);
  };

  const handleChange = (e) => {
    e.persist();
    setTireInstall((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUserChange = (e) => {
    e.persist();
    setTireInstall((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const saveAppointment = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const updateAppointmentResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...tireInstall,
        }),
      }
    ).then((res) => res.json());

    if (updateAppointmentResponse.success)
      alert("Appointment successfully updated.");
    else {
      alert(`Error: ${updateAppointmentResponse.errors[0].msg}`);
    }

    setTireInstall({
      ...updateAppointmentResponse.payload.appointment,
    });
  };

  useEffect(() => {
    const appointment = state.appointments.filter(
      (appointment) => appointment._id === id
    )[0];

    appointment ? setTireInstall(appointment) : getTireAppointment();
  }, [state.appointments]);

  if (!tireInstall) return null;

  return (
    <InstallAptContainer container item>
      <Navbar />
      <ContentSection item container xs={10}>
        <SectionHeading>
          <Typography variant="h4">Tire Intallation Info</Typography>
        </SectionHeading>
        <FormCard>
          <Form>
            <div className="multi-input">
              <Input
                variant="outlined"
                label="First Name"
                placeholder="First Name"
                name="firstName"
                onChange={handleUserChange}
                value={tireInstall.user.firstName}
              />
              <Input
                variant="outlined"
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                onChange={handleUserChange}
                value={tireInstall.user.lastName}
              />
            </div>
            <div className="multi-input">
              <Input
                variant="outlined"
                label="Email"
                placeholder="Email"
                name="email"
                onChange={handleUserChange}
                value={tireInstall.user.email}
              />
              <Input
                variant="outlined"
                label="Phone Number"
                placeholder="Phone Number"
                name="phoneNumber"
                onChange={handleUserChange}
                value={tireInstall.user.phoneNumber}
              />
            </div>
            <div className="address-input">
              <Input
                variant="outlined"
                label="Street Address"
                placeholder="Street Address"
                value={tireInstall.address.formatted_address || ""}
              />
              <Input
                variant="outlined"
                label="Zip Code"
                placeholder="Zip Code"
                value={tireInstall.address.zipCode}
              />
            </div>
            <div className="multi-input">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  label="Appointment Date"
                  value={new Date(tireInstall.date)}
                  onChange={(date) =>
                    setTireInstall((prevState) => ({
                      ...prevState,
                      date,
                      timeWindow: {
                        ...prevState.timeWindow,
                        start: date,
                      },
                    }))
                  }
                />
              </MuiPickersUtilsProvider>
              <Select
                value={tireInstall.status}
                variant="outlined"
                label="Status"
                name="status"
                onChange={(e) =>
                  setTireInstall((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }))
                }
              >
                <MenuItem value="pending">pending</MenuItem>
                <MenuItem value="reviewed">reviewed</MenuItem>
                <MenuItem value="contacted">contacted</MenuItem>
                <MenuItem value="closed">closed - serviced</MenuItem>
                <MenuItem value="lost">closed - not serviced</MenuItem>
              </Select>
              {/* <CustomSelect
                variant="outlined"
                value={tireInstall.timeWindow}
              >
                <MenuItem value="8:00AM - 10:00AM">
                  8:00AM - 10:00AM
                </MenuItem>
                <MenuItem value="10:00AM - 12:00PM">
                  10:00AM - 12:00PM
                </MenuItem>
                <MenuItem value="12:00PM - 2:00PM">
                  12:00PM - 2:00PM
                </MenuItem>
                <MenuItem value="2:00PM - 4:00PM">
                  2:00PM - 4:00PM
                </MenuItem>
              </CustomSelect> */}
            </div>
            <TextareaAutosize
              variant="outlined"
              value={tireInstall.notes}
              name="notes"
              onChange={handleChange}
              className="textarea-notes"
            />
            <Button variant="outlined" onClick={saveAppointment}>
              Update Appointment
            </Button>
          </Form>
        </FormCard>
      </ContentSection>
    </InstallAptContainer>
  );
}
