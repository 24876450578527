import {
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Navbar from "../../components/navbar";
import useGetAppointments from "../../hooks/useGetAppointments";
import useGetClients from "../../hooks/useGetClients";
import useGetStaff from "../../hooks/useGetStaff";
import { Context } from "../../state";

const Main = styled(Grid)``;

const ContentSection = styled(Grid)`
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const CustomTabe = styled(Grid)`
  width: 100%;
`;

const TabelRow = styled(Grid)`
  width: 100%;
  padding: 10px 0;
  display: flex;
  color: #000;
  cursor: pointer;
  background: ${({ status }) =>
    status === "pending"
      ? `rgba(var(--Swyft-Purple))`
      : status === "reviewed" || status === "contacted"
      ? `rgba(255, 255, 0, 1)`
      : status === "closed"
      ? `rgba(var(--Swyft-Green))`
      : `rgba(255, 0, 0, 1)`};
  &:nth-child(1) {
    background: rgb(var(--Swyft-Purple));
    color: #fff;
  }
  &:nth-child(even) {
    opacity: 0.8;
  }
`;

const TabelCell = styled(Grid)`
  padding: 10px 5px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ControlSection = styled(Grid)`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  max-width: 80vw;
`;

export default function TireAppointments() {
  useGetAppointments();
  useGetClients();
  useGetStaff();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [tireAppointments, setTireAppointments] = useState([]);
  const [showClosed, setShowClosed] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Status");
  const [tableColumns, setTableColumns] = useState([
    {
      id: "status",
      text: "Status",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "serviceTypes",
      text: "Service Type",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "user['firstName']",
      text: "Client Name",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "user[email]",
      text: "Email",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "user[phoneNumber",
      text: "Phone Number",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "date",
      text: "Appointment Date",
      sortActive: false,
      sortDirection: "desc",
    },
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (e, page) => {
    setPage(page);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  useEffect(() => {
    setTireAppointments(
      state.appointments.filter((apt) => !apt.serviceTypes.includes("Oil"))
    );
  }, [state.appointments]);

  return (
    <Main container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        <SectionHeading>
          <Typography variant="h4">Tire Appointments</Typography>
        </SectionHeading>
        <ControlSection>
          <FormControlLabel
            control={<Switch checked={showClosed} />}
            label="Show Closed Appointments"
            onChange={() => setShowClosed(!showClosed)}
          ></FormControlLabel>
        </ControlSection>
        <Table>
          <TableHead>
            <TableRow>
              {tableColumns.map((column, index) => (
                <TableCell key={index}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={(e) => handleRequestSort(e, column.id)}
                  >
                    <Typography>{column.text}</Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tireAppointments
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter((apt) => (showClosed ? true : apt.status !== "closed"))
              .map((appointment) => (
                <TableRow
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/dashboard/tire/${appointment._id}`)
                  }
                >
                  <TableCell
                    style={{
                      color:
                        appointment.status === "closed"
                          ? "green"
                          : appointment.status === "confirmed"
                          ? "orange"
                          : "red",
                    }}
                  >
                    <Typography>{appointment.status}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{appointment.serviceTypes[0]}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {appointment.user.firstName} {appointment.user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{appointment.user.email}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{appointment.user.phoneNumber}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {new Date(appointment.date).toLocaleString()}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            rowsPerPage={rowsPerPage}
            page={0}
            count={tireAppointments.length}
            onChangeRowsPerPage={handleRowsPerPage}
            onChangePage={handlePageChange}
          />
        </Table>
      </ContentSection>
    </Main>
  );
}
