import { useEffect, useContext } from "react";
import { Context } from "../state";
import apiRequest from "../api";

const useGetStaff = () => {
  const [state, dispatch] = useContext(Context);

  const fetchStaffList = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const staffResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/employees`,
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    ).then((res) => res.json());

    if (staffResponse.success)
      dispatch({
        type: "INIT_STAFF_LIST",
        payload: staffResponse.payload.employees,
      });
  };

  useEffect(() => {
    fetchStaffList();
  }, []);

  return null;
};

export default useGetStaff;
