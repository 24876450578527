import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { createGlobalStyle } from 'styled-components'
import { StylesProvider } from '@material-ui/core/styles'

const GlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box !important;
}

:root {
  --Swyft-Purple: 122, 93, 168;
  --Swyft-Blue: 57, 133, 174;
  --Swyft-Green: 121, 195, 109;
  --InstagramGradient: circle at 30% 107%, rgb(253,244,151) 0%, rgb(253,244,151) 5%, rgb(253,89,73) 45%, rgb(214,36,159) 60%, rgb(40,90,235) 90%;
  font-size: 16px;
}
`
ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <BrowserRouter>
        <GlobalStyles />
        <App />
      </BrowserRouter>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
