import React, { useRef, useImperativeHandle, useState } from "react";
import { Dialog, Typography, Button } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { TextField } from "@material-ui/core";
import styled from "styled-components";

const Overlay = styled(Dialog)`
  .MuiPaper-root {
    padding: 1.25em;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  .MuiFormControl-root,
  .MuiButtonBase-root {
    margin: 0.625em 0;
  }
`;

function StripeInput({ component: Component, inputRef, ...props }) {
  const elmRef = useRef();
  useImperativeHandle(inputRef, () => ({ focus: () => elmRef.current.focus }));

  return (
    <Component
      onReady={(element) => (elmRef.current = element)}
      {...props}
    ></Component>
  );
}

function StripeForm({ appointment, setIsOpen }) {
  const elements = useElements();
  const stripe = useStripe();
  const [cardName, setCardName] = useState("");
  const [discountCode, setDiscountCode] = useState(
    appointment.transaction?.coupon?.code
  );

  const handlePayment = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    const card = elements.getElement(CardNumberElement);

    const paymentResponse = await stripe.confirmCardPayment(
      appointment.transaction.clientSecret,
      {
        payment_method: {
          card,
          billing_details: {
            name: cardName,
          },
        },
        receipt_email: appointment.user.email,
      }
    );

    if (paymentResponse.error) return alert(paymentResponse.error.message);

    const transactionResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/payments/${appointment.transaction.paymentIntentId}/markPaid`,
      { method: "PUT", headers: { "Content-Type": "application/json" } }
    ).then((res) => res.json());

    appointment.transaction = transactionResponse.payload.transaction;

    return setIsOpen(false);
  };

  const handelCouponSubmit = async (e) => {
    if (!discountCode.trim()) return alert("Please enter a valid coupon code.");

    const paymentIntentResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/payments/${appointment.transaction.paymentIntentId}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cart: appointment.transaction.cart,
          code: discountCode,
          locationId: appointment.transaction.cart[0].locationId[0],
        }),
      }
    ).then((res) => res.json());

    if (!paymentIntentResponse.success) return alert("Invalid coupon code");

    appointment.transaction = {
      ...appointment.transaction,
      ...paymentIntentResponse.payload.transaction,
    };
  };

  return (
    <Form onSubmit={handlePayment}>
      <Typography>
        Amount Due: $
        {(Math.floor(appointment.transaction.amount) / 100).toLocaleString({
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: "currency",
          currency: "USD",
        })}
      </Typography>
      <TextField
        label="Name on Card"
        name="ccname"
        variant="outlined"
        required
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={cardName}
        onChange={(e) => setCardName(e.target.value)}
      />
      <TextField
        label="Card Number"
        name="ccnumber"
        variant="outlined"
        required
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardNumberElement,
          },
        }}
      />
      <TextField
        label="Exp Date"
        name="ccexp"
        variant="outlined"
        required
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardExpiryElement,
          },
        }}
      />
      <TextField
        label="CVC Code"
        name="ccvc"
        variant="outlined"
        required
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardCvcElement,
          },
        }}
      />
      <TextField
        label="Discount Code"
        name="discountCode"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setDiscountCode(e.target.value.toUpperCase())}
        value={discountCode}
      />
      <Button variant="contained" color="default" onClick={handelCouponSubmit}>
        Apply Coupon
      </Button>
      <Button variant="contained" color="primary" type="submit">
        Pay Now
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setIsOpen(false)}
      >
        Cancel
      </Button>
    </Form>
  );
}

export default function PaymentOverlay({ appointment, open, setIsOpen }) {
  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  return (
    <Overlay open={open}>
      <Typography variant="h4">Process Payment</Typography>
      <Elements stripe={stripe}>
        <StripeForm appointment={appointment} setIsOpen={setIsOpen} />
      </Elements>
    </Overlay>
  );
}
