import React, { useEffect, useState, useContext } from "react";
import useGetStaff from "../../hooks/useGetStaff";
import useValidateUser from "../../hooks/useValidateUser";
import styled from "styled-components";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import Navbar from "../../components/navbar";
import { Context } from "../../state";
import { PhoneRounded, EmailRounded } from "@material-ui/icons";
import NewStaffMemberOverlay from "../../components/newStaffMemberOverlay";

const Main = styled(Grid)``;

const ContentSection = styled(Grid)`
  padding: 0;
  margin: 0;
  width: 100%;
  flex-direction: column;
`;

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const ControlSection = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const CustomTabe = styled(Grid)`
  width: 100%;
`;

const TabelRow = styled(Grid)`
  width: 100%;
  padding: 10px 0;
  display: flex;
  background: rgba(var(--Swyft-Purple));
  &:nth-child(even) {
    background: rgba(var(--Swyft-Purple), 0.9);
  }
`;

const TabelCell = styled(Grid)`
  padding: 10px 5px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Staff() {
  useGetStaff();
  const { loading, isAuth_ed } = useValidateUser();
  const [staffDisplay, setStaffDisplay] = useState([]);
  const [searchTearm, setSearchTearm] = useState("");
  const [isNewEmployeeOverlayOpen, setIsNewEmployeeOverlayOpen] =
    useState(false);
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    setStaffDisplay(state.staff);
  }, [state.staff]);

  return (
    <Main container item xs={12}>
      <NewStaffMemberOverlay
        isOpen={isNewEmployeeOverlayOpen}
        setIsOpen={setIsNewEmployeeOverlayOpen}
        staffDisplay={staffDisplay}
        setStaffDisplay={setStaffDisplay}
      />
      <Navbar />
      <ContentSection container item xs={10}>
        <SectionHeading>
          <Typography variant="h4">Staff</Typography>
        </SectionHeading>

        <ControlSection>
          <Button onClick={() => setIsNewEmployeeOverlayOpen(true)}>
            <Typography>New Staff Member +</Typography>
          </Button>
          <TextField
            variant="outlined"
            placeholder="Search Staff"
            value={searchTearm}
            onChange={null}
          />
        </ControlSection>

        <CustomTabe>
          <TabelRow>
            <TabelCell>
              <Typography>Employee Name</Typography>
            </TabelCell>
            <TabelCell>
              <Typography>Phone Number</Typography>
            </TabelCell>
            <TabelCell>
              <Typography>Email Address</Typography>
            </TabelCell>
            <TabelCell>
              <Typography>Tools</Typography>
            </TabelCell>
          </TabelRow>
          {staffDisplay.map((staffMember) => (
            <TabelRow key={staffMember._id}>
              <TabelCell>
                <Typography style={{ textTransform: "capitalize" }}>
                  {staffMember.firstName} {staffMember.lastName}
                </Typography>
              </TabelCell>
              <TabelCell>
                <Typography>{staffMember.phoneNumber}</Typography>
              </TabelCell>
              <TabelCell>
                <Typography>{staffMember.email}</Typography>
              </TabelCell>
              <TabelCell>
                <PhoneRounded />
                <EmailRounded />
              </TabelCell>
            </TabelRow>
          ))}
        </CustomTabe>
      </ContentSection>
    </Main>
  );
}
