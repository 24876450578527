import React, { useEffect, useState } from "react";
import apiRequest from "../../api";
import Layout from "../../components/layout";
import SectionHeading from "../../components/sectionHeading";
import ControllSection from "../../components/controllSection";
import { Button, Paper, Typography } from "@material-ui/core";
import { Delete, Edit, Save } from "@material-ui/icons";
import DealForm from "../../components/dealForm";
import styled from "styled-components";
import { useHistory } from "react-router";

const DealInfo = styled.div`
  margin: 0.5rem;
  max-width: 100%;
  .MuiTypography-root {
    margin: 0.75rem 0;
  }
  .deal-images {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
      width: 100%;
    }
  }
`;

export default function DealPage({
  match: {
    params: { id },
  },
}) {
  const history = useHistory();
  const [deal, setDeal] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const getDeal = async () => {
    const dealResponse = await apiRequest(`/deals/${id}`, "GET");

    if (!dealResponse.success) return;

    setDeal(dealResponse.payload.deal);
  };

  const toggleEditMode = () => setEditMode(!editMode);

  const deleteDeal = async () => {
    if (!window.confirm("Please confirm you want to delete this deal")) return;

    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const delDealResponse = await apiRequest(`/deals/${deal._id}`, "DELETE", {
      Authorization: token,
    });

    if (!delDealResponse.success) return;

    return history.push("/dashboard/deals");
  };

  useEffect(() => {
    getDeal();
  }, []);

  if (!deal) return null;

  return (
    <Layout>
      <SectionHeading text={deal.title} />
      <ControllSection>
        <Button variant="outlined" endIcon={<Edit />} onClick={toggleEditMode}>
          <Typography>Edit</Typography>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          endIcon={<Delete />}
          onClick={deleteDeal}
        >
          <Typography>Delete</Typography>
        </Button>
      </ControllSection>
      {editMode ? (
        <DealForm
          data={deal}
          id={deal._id}
          cancelBtn={() => setEditMode(false)}
        />
      ) : (
        <DealInfo>
          <Typography variant="h3">
            <b>{deal.title}</b>
          </Typography>
          <Typography>
            <b>Start Date:</b> {new Date(deal.startDate).toLocaleString()}
            <br />
            <b>End Date:</b> {new Date(deal.endDate).toLocaleString()}
          </Typography>
          <Typography>
            <b>Is Active:</b> {String(deal.isActive)} - <b>Is Visable:</b>{" "}
            {String(deal.isVisable)}
          </Typography>
          <Typography>
            <b>Description:</b>
            <br />
            {deal.description}
          </Typography>
          {deal.linkToTerms ? (
            <Typography>
              <b>Link To Official Terms:</b> {deal.linkToTerms}
            </Typography>
          ) : null}
          {deal.ctaLink ? (
            <Typography>
              <b>Call To Action Link:</b> {deal.ctaLink}
            </Typography>
          ) : null}
          {deal.ctaBtnText ? (
            <Typography>
              <b>Call To Action Button Text:</b> {deal.ctaBtnText}
            </Typography>
          ) : null}
          <div className="deal-images">
            {deal.thumbnailUrl ? (
              <div>
                <Typography variant="h6">
                  <b>Thumbnail Image</b>
                </Typography>
                <img src={deal.thumbnailUrl} />
              </div>
            ) : null}
            {deal.dealImgUrl ? (
              <div>
                <Typography variant="h6">
                  <b>Main Deal Image</b>
                </Typography>
                <img src={deal.dealImgUrl} />
              </div>
            ) : null}
          </div>
        </DealInfo>
      )}
    </Layout>
  );
}
