import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../state";
import useGetAppointments from "../../hooks/useGetAppointments";
import useGetClients from "../../hooks/useGetClients";
import useGetStaff from "../../hooks/useGetStaff";
import styled from "styled-components";
import Navbar from "../../components/navbar";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import apiRequest from "../../api";
import NewAppointmentOverlay from "../../components/newAppointmentOverlay";
import PaymentOverlay from "../../components/paymentOverlay";
import AppointmentOverlay from "../../components/appointmentOverlay";
import { buildTableHeadColumns } from "../../components/dealsTable";

const Main = styled(Grid)``;

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const ContentSection = styled(Grid)`
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: column;
`;

const ControlSection = styled(Grid)`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  max-width: 80vw;
`;

const SearchBarContainer = styled(Grid)`
  .MuiTextField-root {
    margin: 0 5px;
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px;
  }
`;

const CustomTabe = styled(Grid)`
  width: auto;
`;

const TabelRow = styled(Grid)`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ status }) =>
    status === "pending"
      ? `rgba(255, 0, 0, 1)`
      : status === "confirmed"
      ? `rgba(255, 255, 0, 1)`
      : `rgba(var(--Swyft-Green))`};
  &:nth-child(1) {
    background: rgb(var(--Swyft-Purple));
    color: #fff;
  }
`;

const TabelCell = styled(Grid)`
  padding: 10px 5px;
  display: flex;
  min-width: 200px;
  width: 20vw;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function Appointments() {
  useGetAppointments();
  useGetClients();
  useGetStaff();
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [appointmentDisplay, setAppointmentDisplay] = useState([]);
  const [selectedState, setSelectedState] = useState("Filter by State");
  const [searchTerm, setSearchTerm] = useState("");
  const [showClosed, setShowClosed] = useState(false);

  const sortAppointments = (e) => {
    setSelectedState(e.target.value);
    setAppointmentDisplay(
      state.appointments
        .filter((x) => x.address.formatted_address.includes(e.target.value))
        .filter((appointment) => appointment.serviceTypes.includes("Oil"))
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    setAppointmentDisplay(
      state.appointments
        .filter((appointment) => appointment.serviceTypes.includes("Oil"))
        .filter((oilAppointment) => {
          const { user, vehicles, address, workorders } = oilAppointment;
          const obj = {
            ...user,
            ...vehicles[0],
            ...address,
            ...workorders[0]?.vehicle,
          };

          return Object.values(obj).some((val) =>
            String(val).toLowerCase().includes(e.target.value.toLowerCase())
          );
        })
    );
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Status");
  const [tableColumns, setTableColumns] = useState([
    {
      id: "status",
      text: "Status",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "user.firstName",
      text: "Client Name",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "user.email",
      text: "Email Address",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "tech.firstName",
      text: "Technician",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "createdAt",
      text: "Scheduled Date",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "date",
      text: "Appointment Date",
      sortActive: false,
      sortDirection: "desc",
    },
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (e, page) => {
    setPage(page);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  useEffect(() => {
    setAppointmentDisplay(
      state.appointments.filter((appointment) =>
        appointment.serviceTypes.includes("Oil")
      )
    );
  }, [state.appointments]);

  return (
    <Main container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        <AppointmentOverlay></AppointmentOverlay>
        <SectionHeading>
          <Typography variant="h4">Appointments</Typography>
        </SectionHeading>
        <ControlSection>
          <SearchBarContainer>
            <Select
              onChange={sortAppointments}
              value={selectedState}
              variant="outlined"
            >
              <MenuItem disabled value="Filter by State">
                Filter by State
              </MenuItem>
              <MenuItem value="CO">CO</MenuItem>
              <MenuItem value="NV">NV</MenuItem>
            </Select>
            <TextField
              variant="outlined"
              placeholder="Search Appointment"
              value={searchTerm}
              onChange={handleSearch}
            ></TextField>
          </SearchBarContainer>
          <FormControlLabel
            control={<Switch checked={showClosed} />}
            label="Show Closed Appointments"
            onChange={() => setShowClosed(!showClosed)}
          ></FormControlLabel>
        </ControlSection>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map((column, index) => (
                  <TableCell key={index}>
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={(e) => handleRequestSort(e, column.id)}
                    >
                      <Typography>{column.text}</Typography>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {appointmentDisplay
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter((apt) => (showClosed ? true : apt.status !== "closed"))
                .map((appointment) => (
                  <TableRow
                    onClick={() =>
                      history.push(`/dashboard/appointments/${appointment._id}`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      style={{
                        color:
                          appointment.status === "closed"
                            ? "green"
                            : appointment.status === "confirmed"
                            ? "orange"
                            : "red",
                      }}
                    >
                      <Typography>{appointment.status}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {appointment.user.firstName} {appointment.user.lastName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{appointment.user.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {appointment.tech
                          ? `${appointment.tech.firstName} ${appointment.tech.lastName}`
                          : "Unassigned"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {new Date(appointment.createdAt).toLocaleString()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {new Date(
                          appointment.timeWindow.start
                        ).toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              rowsPerPage={rowsPerPage}
              count={appointmentDisplay.length}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPage}
            />
          </Table>
        </TableContainer>
      </ContentSection>
    </Main>
  );
}
