import { useJsApiLoader } from '@react-google-maps/api'

const libraries = ['places']

export default function useGoogleMaps() {
  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:
      'AIzaSyDgeBVKcke06_NLUTKPGxWiROUysSvMo0s',
    libraries,
  })
}
