import styled from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import { Button, Card, Grid } from "@material-ui/core";
import { Context } from "../../state";
import SectionHeading from "../../components/sectionHeading";
import CouponForm from "../../components/couponForm";
import apiRequest from "../../api";

const Main = styled(Grid)`
  .coupon-card {
    margin: 0.75rem;
  }
`;

const ContentSection = styled(Grid)`
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: column;
`;

export default function Coupon({
  match: {
    params: { id },
  },
}) {
  const [state, dispatch] = useContext(Context);
  const [coupon, setCoupon] = useState(null);
  const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

  const getCoupon = async () => {
    const couponResponse = await apiRequest(`/coupons/${id}`, "GET", {
      Authorization: token,
    });

    if (!couponResponse.success) return;

    const { coupon } = couponResponse.payload;

    setCoupon({
      ...coupon,
      discount: {
        ...coupon.discount,
        amount: coupon.discount.isFixedAmount
          ? coupon.discount.amount / 100
          : coupon.discount.amount * 100,
      },
    });
  };

  useEffect(() => {
    const coupon = state.coupons.filter((coupon) => coupon._id === id)[0];

    if (coupon)
      setCoupon({
        ...coupon,
        discount: {
          ...coupon.discount,
          amount: coupon.discount.isFixedAmount
            ? coupon.discount.amount / 100
            : coupon.discount.amount * 100,
        },
      });
    else getCoupon();
  }, []);

  if (!coupon) return null;

  return (
    <Main container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        <SectionHeading text={coupon.code} />
        <Card className="coupon-card">
          <CouponForm coupon={coupon} setCoupon={setCoupon} id={id} />
        </Card>
      </ContentSection>
    </Main>
  );
}
