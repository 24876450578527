import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Button,
  FormControl,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState, useContext, Fragment } from "react";
import styled from "styled-components";
import Navbar from "../../components/navbar";
import { Context } from "../../state";
import { addHours } from "date-fns";
import useGetStaff from "../../hooks/useGetStaff";

const Main = styled(Grid)`
  width: 100%;
`;

const ContentSection = styled(Grid)`
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: column;
`;

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const AppointmentContainer = styled(Grid)`
  width: 100%;
`;

const SideBar = styled(Grid)`
  width: 100%;
`;

const TireInfoCard = styled(Card)`
  margin: 20px auto;
  padding: 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    align-self: flex-start;
  }
  .supplier {
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    border: solid 1px #000;
  }
  .quote-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  h6 {
    margin: 20px auto;
  }
`;

const Img = styled.img`
  margin: 20px auto;
  width: auto;
  height: 150px;
`;

const ClientCard = styled(Card)`
  margin: 20px 10px;
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .appointment-info {
    width: 100%;
    padding: 0 10px;
    h6 {
      margin: 10px 0;
    }
    p {
      margin: 3px 0;
      padding: 0 0 0 10px;
    }
  }
`;

const ClientAvatar = styled.div`
  margin: 20px 0;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(props) => props.bgColor};
  color: #fff;
  h2 {
    font-weight: bolder;
  }
`;

const MainAppointmentInfoContainer = styled(Grid)`
  width: 100%;
`;

const MainAppointmentInfoCard = styled(Card)`
  margin: 20px 10px;
  padding: 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  h5,
  h6 {
    margin: 20px 0;
  }
  .MuiGrid-root {
    display: flex;
    flex-wrap: nowrap;
  }
  .MuiInputBase-root {
    margin: 10px;
    height: 50px;
  }
  .inspection_photos {
    padding: 10px;
    width: 100%;
    display: flex;
    overflow: scroll;
    img {
      padding: 10px;
      width: auto;
      height: 400px;
    }
  }
`;

const NotesInputs = styled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    margin: 10px;
    height: 150px;
  }
`;

const CustomeTextField = styled(TextField)`
  width: 100%;
`;

export default function TireAppointment(props) {
  useGetStaff();
  const { id } = props.match.params;
  const [state, dispatch] = useContext(Context);
  const [tireAppointment, setTireAppointment] = useState(null);
  const [selectedTech, setSelectedTech] = useState("Unassigned");
  const [avatarColor, setAvatarColor] = useState(null);
  const [photosArray, setPhotosArray] = useState([]);

  const getAppointmentInfo = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const appointmentRequest = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments/${id}`,
      {
        method: "GET",
        headers: { Authorization: token },
      }
    ).then((res) => res.json());

    if (appointmentRequest.success)
      setTireAppointment(appointmentRequest.payload.appointment);

    if (appointmentRequest.payload.appointment.tech)
      setSelectedTech(appointmentRequest.payload.appointment.tech);

    if (appointmentRequest.payload.appointment.workorders.length > 0)
      getAppointmentPhotos(appointmentRequest.payload.appointment);
  };

  const assignTech = async (e) => {
    const techId = e.target.value;

    setSelectedTech(techId);

    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const assignTechResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/itineraries`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          techId,
          appointmentId: tireAppointment._id,
        }),
      }
    ).then((res) => res.json());

    if (assignTechResponse.success) alert(`Appointment successfully assigned.`);
  };

  const handleChange = (e) => {
    e.persist();
    setTireAppointment((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUserChange = (e) => {
    e.persist();
    setTireAppointment((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const saveAppointment = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const updateAppointmentResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...tireAppointment,
        }),
      }
    ).then((res) => res.json());

    if (updateAppointmentResponse.success)
      alert("Appointment successfully updated.");
    else {
      alert(`Error: ${updateAppointmentResponse.errors[0].msg}`);
    }

    setTireAppointment({
      ...updateAppointmentResponse.payload.appointment,
    });
  };

  const confirmAppointment = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const confirmAppointmentResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/appointments/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...tireAppointment,
          status: "confirmed",
        }),
      }
    ).then((res) => res.json());

    if (confirmAppointmentResponse.success)
      alert("Appointment successfully confirmed.");
    else {
      alert(`Error: ${confirmAppointmentResponse.error.msg}`);
    }

    setTireAppointment({
      ...tireAppointment,
      ...confirmAppointmentResponse.payload.appointment,
    });
  };

  const getAppointmentPhotos = async (appointment) => {
    const { workorders } = appointment;
    const photos = await Promise.all(
      workorders.map(async (workorder) => {
        const photos = await fetch(
          `${process.env.REACT_APP_API_URL}/inspections/${workorder.appointment}/${workorder.vehicle._id}`
        ).then((res) => res.json());

        if (!photos.success) return [];

        return photos.payload.photos;
      })
    );

    setPhotosArray(photos.flat());
  };

  useEffect(() => {
    setAvatarColor(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
    getAppointmentInfo();
  }, [state.appointments]);

  if (!tireAppointment) return null;

  return (
    <Main container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        <SectionHeading>
          <Typography variant="h4">Appointment</Typography>
        </SectionHeading>
        <AppointmentContainer container item>
          <SideBar container item xs={4}>
            <ClientCard>
              <Typography variant="h5">
                {tireAppointment.user.firstName} {tireAppointment.user.lastName}
              </Typography>
              <ClientAvatar bgColor={avatarColor}>
                <Typography variant="h2">
                  {tireAppointment.user.firstName.charAt(0).toUpperCase()}
                </Typography>
              </ClientAvatar>
              <div className="appointment-info">
                <Typography variant="h6">Client Information</Typography>
                <Typography>
                  Phone Number: {tireAppointment.user.phoneNumber}
                </Typography>
                <Typography>
                  Email Address: {tireAppointment.user.email}
                </Typography>
                <Typography variant="h6">Appointment Information</Typography>
                <Typography>
                  Appointment Status:{" "}
                  <Select
                    value={tireAppointment.status}
                    onChange={(e) =>
                      setTireAppointment({
                        ...tireAppointment,
                        status: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="pending">
                      <Typography>pending</Typography>
                    </MenuItem>
                    <MenuItem value="confirmed">
                      <Typography>confirmed</Typography>
                    </MenuItem>
                    <MenuItem value="closed">
                      <Typography>closed</Typography>
                    </MenuItem>
                    <MenuItem value="lost">
                      <Typography>lost</Typography>
                    </MenuItem>
                  </Select>
                </Typography>
                <Typography>
                  Assigned Technician:
                  <Select value={selectedTech} onChange={assignTech}>
                    <MenuItem disabled>Unassigned</MenuItem>
                    {state.staff.map((staffMember) => (
                      <MenuItem key={staffMember._id} value={staffMember._id}>
                        {staffMember.firstName} {staffMember.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </Typography>
                <Typography>Service: {tireAppointment.serviceTypes}</Typography>
                <Typography>
                  Service Address: {tireAppointment.address.formatted_address}
                </Typography>
                <Typography>
                  Appointment Date:{" "}
                  {new Date(tireAppointment.timeWindow.start).toLocaleString()}
                </Typography>
                <Typography>
                  Time Windwow:{" "}
                  {new Date(tireAppointment.timeWindow.start).getHours()} -{" "}
                  {new Date(tireAppointment.timeWindow.end).getHours()}
                </Typography>
              </div>
            </ClientCard>

            {tireAppointment.quote ? (
              <TireInfoCard>
                {tireAppointment.quote.tires.map((tire) => (
                  <Fragment>
                    <Typography variant="h5">
                      {tire.brand} {tire.model}
                    </Typography>
                    <Img src={tire.image} />
                    <Typography variant="h6">Tire Info</Typography>
                    <Typography>
                      <b>Brand:</b> {tire.brand}
                    </Typography>
                    <Typography>
                      <b>Model:</b> {tire.model}
                    </Typography>
                    <Typography>
                      <b>Size:</b> {tire.size}
                    </Typography>
                    <Typography>
                      <b>Part Number:</b> {tire.part_number}
                    </Typography>
                    <Typography>
                      <b>Price:</b> ${tire.price.toFixed(2)}
                    </Typography>
                    <br />
                  </Fragment>
                ))}
                <Typography variant="h6">Quote Information</Typography>
                {tireAppointment.quote.tires.map((tire) => (
                  <Fragment>
                    <Typography className="quote-info">
                      <b>
                        {tire.brand} {tire.model} {tire.size}
                      </b>
                      <span>${(tire.price * tire.quantity).toFixed(2)}</span>
                    </Typography>
                    <br />
                  </Fragment>
                ))}
                {tireAppointment.quote.services.map((x) => (
                  <Typography className="quote-info">
                    <b>{x.name}: </b>
                    <span>${x.total_price.toFixed(2)}</span>
                  </Typography>
                ))}
                <br />
                <Typography className="quote-info">
                  <b>Sub Total: </b>
                  <span>${tireAppointment.quote.sub_total.toFixed(2)}</span>
                </Typography>
                <Typography className="quote-info">
                  <b>Tax: </b>
                  <span>${tireAppointment.quote.tax.toFixed(2)}</span>
                </Typography>
                <Typography className="quote-info">
                  <b>Total: </b>
                  <span>${tireAppointment.quote.price}</span>
                </Typography>
                <Typography variant="h6">Supplier Info</Typography>
                {tireAppointment.quote.tires.map((tire) => (
                  <Fragment>
                    <Typography>
                      <b>Supplier Name:</b> {tire.supplier_details.name}
                    </Typography>
                    {tire.supplier_details.stock.map((x) => (
                      <div className="supplier">
                        <Typography>
                          {tire.brand} {tire.model} {tire.size}
                        </Typography>
                        <Typography>
                          <b>Part Number:</b> {tire.part_number}
                        </Typography>
                        <Typography>
                          <b>Branch: </b>
                          {x.branch}
                        </Typography>
                        <Typography>
                          <b>Quantity: </b>
                          {x.quantity}
                        </Typography>
                      </div>
                    ))}
                  </Fragment>
                ))}
              </TireInfoCard>
            ) : null}
            {tireAppointment.transaction ? (
              <TireInfoCard>
                <Typography variant="h5">Transaction</Typography>
                <Typography>
                  <b>Transaction Status:</b>{" "}
                  {tireAppointment.transaction.status}
                </Typography>
                <Typography>
                  <b>Total:</b> $
                  {(
                    Math.floor(tireAppointment.transaction.amount) / 100
                  ).toLocaleString({
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
                <Typography>
                  <b>Amount Paid:</b> $
                  {(
                    Math.floor(tireAppointment.transaction.amount) / 100
                  ).toLocaleString({
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  })}
                </Typography>
              </TireInfoCard>
            ) : null}
          </SideBar>

          <MainAppointmentInfoContainer container item xs={8}>
            <MainAppointmentInfoCard>
              <FormControl>
                <Typography variant="h5">Client Information</Typography>
                <Grid container item xs={12}>
                  <CustomeTextField
                    variant="outlined"
                    placeholder="First Name"
                    value={tireAppointment.user.firstName}
                    onChange={(e) =>
                      setTireAppointment({
                        ...tireAppointment,
                        user: {
                          ...tireAppointment.user,
                          firstName: e.target.value,
                        },
                      })
                    }
                  />
                  <CustomeTextField
                    variant="outlined"
                    placeholder="Last Name"
                    value={tireAppointment.user.lastName}
                    onChange={(e) =>
                      setTireAppointment({
                        ...tireAppointment,
                        user: {
                          ...tireAppointment.user,
                          lastName: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid>
                  <CustomeTextField
                    variant="outlined"
                    placeholder="Email Address"
                    value={tireAppointment.user.email}
                    onChange={(e) =>
                      setTireAppointment({
                        ...tireAppointment,
                        user: {
                          ...tireAppointment.user,
                          email: e.target.value,
                        },
                      })
                    }
                  />
                  <CustomeTextField
                    variant="outlined"
                    placeholder="Phone Number"
                    value={tireAppointment.user.phoneNumber}
                    onChange={(e) =>
                      setTireAppointment({
                        ...tireAppointment,
                        user: {
                          ...tireAppointment.user,
                          phoneNumber: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Typography variant="h5">Appointment Information</Typography>
                <Grid container item xs={12}>
                  <CustomeTextField
                    variant="outlined"
                    placeholder="Service Address"
                    value={tireAppointment.address.formatted_address}
                    onChange={(e) =>
                      setTireAppointment({
                        ...tireAppointment,
                        address: {
                          ...tireAppointment.address,
                          formatted_address: e.target.value,
                        },
                      })
                    }
                  />
                </Grid>
                <Grid container item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={tireAppointment.date}
                      onChange={(e) =>
                        setTireAppointment({
                          ...tireAppointment,
                          date: e.toISOString(),
                          timeWindow: {
                            start: new Date(
                              new Date(e).setHours(
                                new Date(
                                  tireAppointment.timeWindow.start
                                ).getHours(),
                                0,
                                0,
                                0
                              )
                            ),
                            end: new Date(
                              addHours(
                                new Date(e).setHours(
                                  new Date(
                                    tireAppointment.timeWindow.start
                                  ).getHours(),
                                  0,
                                  0,
                                  0
                                ),
                                2
                              )
                            ),
                          },
                        })
                      }
                    />
                    <Select
                      variant="outlined"
                      value={new Date(
                        tireAppointment.timeWindow.start
                      ).getHours()}
                      onChange={(e) =>
                        setTireAppointment({
                          ...tireAppointment,
                          timeWindow: {
                            start: new Date(
                              new Date(tireAppointment.date).setHours(
                                parseInt(e.target.value),
                                0,
                                0,
                                0
                              )
                            ),
                            end: new Date(
                              addHours(
                                new Date(tireAppointment.date).setHours(
                                  parseInt(e.target.value),
                                  0,
                                  0,
                                  0
                                ),
                                2
                              )
                            ),
                          },
                        })
                      }
                    >
                      <MenuItem value="8">
                        <Typography>8:00AM - 10:00AM</Typography>
                      </MenuItem>
                      <MenuItem value="10">
                        <Typography>10:00AM - 12:00PM</Typography>
                      </MenuItem>
                      <MenuItem value="12">
                        <Typography>12:00PM - 2:00PM</Typography>
                      </MenuItem>
                      <MenuItem value="14">
                        <Typography>2:00PM - 4:00PM</Typography>
                      </MenuItem>
                    </Select>
                  </MuiPickersUtilsProvider>
                </Grid>
                <NotesInputs
                  variant="outlined"
                  placeholder="Appointment Notes"
                  label="Appointment Notes"
                  name="notes"
                  onChange={(e) =>
                    setTireAppointment({
                      ...tireAppointment,
                      [e.target.name]: e.target.value,
                    })
                  }
                  multiline={true}
                  value={tireAppointment.notes}
                />
                {tireAppointment.vehicles.map((vehicle, index) => (
                  <>
                    <Typography variant="h5">Vehicle</Typography>
                    <Grid container item xs={12}>
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Vin"
                        value={vehicle.vin}
                        onChange={(e) => {
                          const vehicles = [...tireAppointment.vehicles];

                          vehicles[index].vin = e.target.value.toUpperCase();

                          setTireAppointment({
                            ...tireAppointment,
                            vehicles,
                          });
                        }}
                      />
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Mileage"
                        value={vehicle.mileage}
                        onChange={(e) => {
                          const vehicles = [...tireAppointment.vehicles];

                          vehicles[index].mileage = e.target.value;

                          setTireAppointment({
                            ...tireAppointment,
                            vehicles,
                          });
                        }}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Year"
                        value={vehicle.year}
                        onChange={(e) => {
                          const vehicles = [...tireAppointment.vehicles];

                          vehicles[index].year = e.target.value;

                          setTireAppointment({
                            ...tireAppointment,
                            vehicles,
                          });
                        }}
                      />
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Make"
                        value={vehicle.make}
                        onChange={(e) => {
                          const vehicles = [...tireAppointment.vehicles];

                          vehicles[index].make = e.target.value;

                          setTireAppointment({
                            ...tireAppointment,
                            vehicles,
                          });
                        }}
                      />
                      <CustomeTextField
                        variant="outlined"
                        placeholder="Vehicle Model"
                        value={vehicle.model}
                        onChange={(e) => {
                          const vehicles = [...tireAppointment.vehicles];

                          vehicles[index].model = e.target.value;

                          setTireAppointment({
                            ...tireAppointment,
                            vehicles,
                          });
                        }}
                      />
                    </Grid>
                  </>
                ))}
                <Button onClick={confirmAppointment}>
                  <Typography color="primary">Confirm Appointment</Typography>
                </Button>
                <Button onClick={saveAppointment}>
                  <Typography color="primary">Save Appointment</Typography>
                </Button>
              </FormControl>
            </MainAppointmentInfoCard>

            {tireAppointment.workorders.map((workorder) => (
              <MainAppointmentInfoCard>
                <Typography variant="h5">
                  {workorder.vehicle.year} {workorder.vehicle.make}{" "}
                  {workorder.vehicle.model} - {workorder.vehicle.vin}
                </Typography>
                <Typography>
                  <b>VIN: </b>
                  {workorder.vehicle.vin}
                </Typography>
                <Typography>
                  <b>Year: </b>
                  {workorder.vehicle.year}
                </Typography>
                <Typography>
                  <b>Make: </b>
                  {workorder.vehicle.make}
                </Typography>
                <Typography>
                  <b>Model: </b>
                  {workorder.vehicle.model}
                </Typography>
                {workorder.vehicle.trim ? (
                  <Typography>
                    <b>Trim: </b>
                    {workorder.vehicle.trim}
                  </Typography>
                ) : null}
                <Typography>
                  <b>Mileage: </b>
                  {workorder.vehicle.mileage.toLocaleString()}
                </Typography>
                <Typography>
                  <b>Engine Size: </b>
                  {workorder.vehicle.engineSize}
                </Typography>
                <Typography>
                  <b>Plate Number: </b>
                  {workorder.vehicle.plateNumber}
                </Typography>
                <Typography>
                  <b>Service: </b>
                  {workorder.vehicle.oilPackage}
                </Typography>
                <Typography variant="h6">Tire Information</Typography>
                {workorder.evaluation.tires.map((tire) => (
                  <Typography>
                    <b>{tire.tireLocation} Tire: </b>
                    {tire.psi}psi / {tire.treadDepth}mm
                  </Typography>
                ))}
                {photosArray.length > 0 ? (
                  <>
                    <Typography variant="h5">Inspection Photos</Typography>
                    <div className="inspection_photos">
                      {photosArray
                        .filter(
                          (photo) => photo.vehicle === workorder.vehicle._id
                        )
                        .map((photo) => (
                          <img src={photo.url} key={photo._id} alt="" />
                        ))}
                    </div>
                  </>
                ) : null}
              </MainAppointmentInfoCard>
            ))}
          </MainAppointmentInfoContainer>
        </AppointmentContainer>
      </ContentSection>
    </Main>
  );
}
