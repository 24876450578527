import {
  Grid,
  Typography,
  Button,
  Dialog,
  Card,
  DialogTitle,
  Table,
  TableHead,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import apiRequest from "../../api";
import CouponForm from "../../components/couponForm";
import Navbar from "../../components/navbar";
import SectionHeading from "../../components/sectionHeading";
import { Context } from "../../state";

const Main = styled(Grid)``;

const ContentSection = styled(Grid)`
  margin: 0;
  padding: 0;
  width: 100%;
  flex-direction: column;
`;

const ControllSection = styled.div`
  width: 100%;
  padding: 0.75rem;
`;

export default function Coupons() {
  const history = useHistory();
  const [state, dispatch] = useContext(Context);
  const [coupon, setCoupon] = useState({
    isActive: true,
    isOneTimeUse: false,
    discount: {
      isFixedAmount: true,
    },
    source: "in-house",
  });
  const [isOpen, setIsOpen] = useState(false);

  const getCoupons = async () => {
    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const couponsResponse = await apiRequest("/coupons", "GET", {
      Authorization: token,
    });

    if (!couponsResponse.success) return;

    dispatch({ type: "SET_COUPONS", payload: couponsResponse.payload.coupons });
  };

  const formatCurrency = (num) =>
    Number(num / 100).toLocaleString({
      style: "currency",
      currency: "USD",
    });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Status");
  const [tableColumns, setTableColumns] = useState([
    {
      id: "code",
      text: "Code",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "isOneTimeUse",
      text: "One Time Use",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "isPercent",
      text: "Is Percent",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "discount",
      text: "Discount",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "useage",
      text: "Useage",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "source",
      text: "Source",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "isActive",
      text: "Is Active",
      sortActive: false,
      sortDirection: "desc",
    },
    {
      id: "expirationDate",
      text: "Expiration Date",
      sortActive: false,
      sortDirection: "desc",
    },
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (e, page) => {
    setPage(page);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  useEffect(() => {
    getCoupons();
  }, []);

  return (
    <Main container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        <SectionHeading text="Coupons" />
        <ControllSection>
          <Button variant="outlined" onClick={() => setIsOpen(!isOpen)}>
            Create Coupon
          </Button>
        </ControllSection>
        <Table>
          <TableHead>
            {tableColumns.map((column, index) => (
              <TableCell key={index}>
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={(e) => handleRequestSort(e, column.id)}
                >
                  <Typography>{column.text}</Typography>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {state.coupons
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((coupon) => (
                <TableRow
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/dashboard/coupons/${coupon._id}`)
                  }
                  key={coupon._id}
                >
                  <TableCell>
                    <Typography>{coupon.code}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{String(coupon.isOneTimeUse)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {String(!coupon.discount.isFixedAmount)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {coupon.discount.isFixedAmount
                        ? `$${formatCurrency(coupon.discount.amount)}`
                        : `${Number(coupon.discount.amount * 100)}%`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{coupon.usage}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{coupon.source}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{String(coupon.isActive)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {new Date(coupon.expirationDate).toLocaleDateString()}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            page={page}
            rowsPerPage={rowsPerPage}
            count={state.coupons.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPage}
          />
        </Table>
      </ContentSection>
      <Dialog open={isOpen}>
        <DialogTitle>Create New Coupon</DialogTitle>
        <Card>
          <CouponForm
            coupon={coupon}
            setCoupon={setCoupon}
            setIsOpen={setIsOpen}
          />
        </Card>
      </Dialog>
    </Main>
  );
}
