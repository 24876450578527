import { Typography } from "@material-ui/core";
import styled from "styled-components";
import React from "react";

const Heading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export default function SectionHeading({ text }) {
  return (
    <Heading>
      <Typography variant="h4">{text}</Typography>
    </Heading>
  );
}
