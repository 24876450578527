import React from "react";
import { Switch, Route } from "react-router-dom";
import Store from "./state";
import Login from "./pages/login/";
import Dashboard from "./pages/dashboard/";
import Staff from "./pages/staff/";
import Appointments from "./pages/appointments";
import Clients from "./pages/clients/";
import Client from "./pages/client";
import Appointment from "./pages/appointment";
import TireAppointments from "./pages/tireAppointments";
import TireAppointment from "./pages/tireAppointment";
import TireInstallationApts from "./pages/tireInstallations";
import TireInstallApt from "./pages/tireInstallation";
import useGoogleMaps from "./hooks/useGoogleMaps";
import Coupons from "./pages/coupons";
import Coupon from "./pages/coupon";
import DealsPage from "./pages/deals";
import DealPage from "./pages/deal";

const App = () => {
  useGoogleMaps();
  return (
    <Store>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/dashboard/staff" component={Staff} />
        <Route exact path="/dashboard/appointments" component={Appointments} />
        <Route
          exact
          path="/dashboard/appointments/:id"
          component={Appointment}
        />
        <Route exact path="/dashboard/clients" component={Clients} />
        <Route exact path="/dashboard/clients/:id" component={Client} />
        <Route exact path="/dashboard/tire" component={TireAppointments} />
        <Route
          exact
          path="/dashboard/tire/install"
          component={TireInstallationApts}
        />
        <Route
          exact
          path="/dashboard/tire/install/:id"
          component={TireInstallApt}
        />
        <Route exact path="/dashboard/tire/:id" component={TireAppointment} />
        <Route exact path="/dashboard/coupons" component={Coupons} />
        <Route exact path="/dashboard/coupons/:id" component={Coupon} />
        <Route exact path="/dashboard/deals" component={DealsPage} />
        <Route exact path="/dashboard/deals/:id" component={DealPage} />
      </Switch>
    </Store>
  );
};

export default App;
