import {
  Button,
  CardActions,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  h5,
  h6 {
    margin: 20px 0;
  }
  .MuiGrid-root {
    display: flex;
    flex-wrap: nowrap;
  }
  .MuiInputBase-root {
    margin: 10px;
    height: 2.5rem;
  }
  div {
    display: flex;
    justify-content: space-between;
  }
`;

export default function WorkorderForm({
  workorderInfo = {
    vehicle: {
      vin: "",
      year: "",
      make: "",
      model: "",
      mileage: "",
      trim: "",
      engineSize: "",
      plateNumber: "",
      oilPackage: "",
    },
    evaluation: {
      tires: [
        {
          tireLocation: "Front Driver",
          psi: null,
          treadDepth: null,
        },
        {
          tireLocation: "Front Passenger",
          psi: null,
          treadDepth: null,
        },
        {
          tireLocation: "Rear Passenger",
          psi: null,
          treadDepth: null,
        },
        {
          tireLocation: "Rear Driver",
          psi: null,
          treadDepth: null,
        },
      ],
      serviceLight: false,
    },
  },
  setMasterWorkorder = () => {},
  setIsEditMode = () => {},
  appointmentId,
  vehicleId,
  oilPackage,
}) {
  const [workorder, setWorkorder] = useState(workorderInfo);
  const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

  const handleVinChange = async (e) => {
    setWorkorder((prevState) => ({
      ...prevState,
      vehicle: {
        ...prevState.vehicle,
        [e.target.name]: e.target.value,
      },
    }));

    if (e.target.value.length === 17) {
      const vinDecodeResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/vehicles/decode/${e.target.value}`
      ).then((res) => res.json());

      if (!vinDecodeResponse.success) return;

      vinDecodeResponse.payload.forEach((e) => {
        console.log(e);
        if (e.VariableId === 26)
          setWorkorder((prevState) => ({
            ...prevState,
            vehicle: { ...prevState.vehicle, make: e.Value },
          }));

        if (e.VariableId === 29)
          setWorkorder((prevState) => ({
            ...prevState,
            vehicle: { ...prevState.vehicle, year: e.Value },
          }));

        if (e.VariableId === 28)
          setWorkorder((prevState) => ({
            ...prevState,
            vehicle: { ...prevState.vehicle, model: e.Value },
          }));

        if (e.VariableId === 13)
          setWorkorder((prevState) => ({
            ...prevState,
            vehicle: { ...prevState.vehicle, engineSize: `${e.Value}L` },
          }));

        if (e.VariableId === 38)
          setWorkorder((prevState) => ({
            ...prevState,
            vehicle: { ...prevState.vehicle, trim: e.Value },
          }));
      });
    }
  };
  const handleVehicleInfoChange = (e) =>
    setWorkorder({
      ...workorder,
      vehicle: {
        ...workorder.vehicle,
        [e.target.name]: e.target.value,
      },
    });

  const handleTireInfoChange = (e, index) => {
    const tireArray = workorder.evaluation.tires;
    tireArray[index] = { ...tireArray[index], [e.target.name]: e.target.value };

    setWorkorder({
      ...workorder,
      evaluation: { ...workorder.evaluation, tires: tireArray },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let workorderResponse;

    appointmentId
      ? (workorderResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/workorders/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              ...workorder,
              appointment: appointmentId,
              vehicle: { ...workorder.vehicle, _id: vehicleId, oilPackage },
            }),
          }
        ).then((res) => res.json()))
      : (workorderResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/workorders/${workorder._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              ...workorder,
              vehicle: {
                ...workorder.vehicle,
                mileage: parseInt(
                  `${workorder.vehicle.mileage}`.replace(/,/g, "")
                ),
              },
            }),
          }
        ).then((res) => res.json()));

    if (!workorderResponse.success) return alert(workorderResponse.error.msg);

    setMasterWorkorder(workorderResponse.payload.workorder);

    setIsEditMode(false);

    return appointmentId ? window.location.reload() : null;
  };

  const deleteWorkorder = async () => {
    const deleteWorkorderResponse = await fetch(
      `${process.env.REACT_APP_API_URL}/workorders/${workorder._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    ).then((res) => res.json());

    if (!deleteWorkorderResponse.success) return;

    setIsEditMode(false);

    return window.location.reload();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Typography variant="h5">New Workorder</Typography>
      <TextField
        variant="outlined"
        placeholder="VIN"
        label="VIN"
        name="vin"
        value={workorder.vehicle.vin}
        onChange={handleVinChange}
      />
      <div>
        <TextField
          variant="outlined"
          placeholder="Year"
          label="Year"
          name="year"
          value={workorder.vehicle.year}
          onChange={handleVehicleInfoChange}
        />
        <TextField
          variant="outlined"
          placeholder="Make"
          label="Make"
          name="make"
          value={workorder.vehicle.make}
          onChange={handleVehicleInfoChange}
        />
        <TextField
          variant="outlined"
          placeholder="Model"
          label="Model"
          name="model"
          value={workorder.vehicle.model}
          onChange={handleVehicleInfoChange}
        />
      </div>

      {workorder.vehicle.trim ? (
        <TextField
          variant="outlined"
          placeholder="Trim"
          label="Trim"
          name="trim"
          value={workorder.vehicle.trim}
          onChange={handleVehicleInfoChange}
        />
      ) : null}

      <TextField
        variant="outlined"
        placeholder="Mileage"
        label="Mileage"
        name="mileage"
        value={workorder.vehicle.mileage.toLocaleString("en-US")}
        onChange={handleVehicleInfoChange}
      />

      <TextField
        variant="outlined"
        placeholder="Engine Size"
        label="Engine Size"
        name="engineSize"
        value={workorder.vehicle.engineSize}
        onChange={handleVehicleInfoChange}
      />

      <TextField
        variant="outlined"
        placeholder="Plate Number"
        label="Plate Number"
        name="plateNumber"
        value={workorder.vehicle.plateNumber}
        onChange={handleVehicleInfoChange}
      />

      {workorder.evaluation.tires.map((tire, index) => (
        <div style={{ width: "100%", display: "flex", flex: 1 }}>
          <TextField
            variant="outlined"
            placeholder={`${tire.tireLocation} PSI`}
            label={`${tire.tireLocation} PSI`}
            name="psi"
            value={tire.psi}
            onChange={(e) => handleTireInfoChange(e, index)}
            style={{ width: "100%" }}
          />
          <TextField
            variant="outlined"
            placeholder={`${tire.tireLocation} Tread Depth`}
            label={`${tire.tireLocation} Tread Depth`}
            name="treadDepth"
            value={tire.treadDepth}
            onChange={(e) => handleTireInfoChange(e, index)}
            style={{ width: "100%" }}
          />
        </div>
      ))}

      <FormControlLabel
        control={
          <Switch
            checked={workorder.evaluation.serviceLight}
            onChange={() =>
              setWorkorder({
                ...workorder,
                evaluation: {
                  ...workorder.evaluation,
                  serviceLight: !workorder.evaluation.serviceLight,
                },
              })
            }
          />
        }
        label="Service Engine Light On?"
      />
      <CardActions>
        <div>
          <Button variant="contained" color="primary" type="submit">
            <Typography>Save Workorder</Typography>
          </Button>
          {appointmentId ? null : (
            <Button color="secondary" onClick={deleteWorkorder}>
              Delete Workorder
            </Button>
          )}
        </div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsEditMode(false)}
        >
          <Typography>Cancel</Typography>
        </Button>
      </CardActions>
    </Form>
  );
}
