const Reducer = (state, action) => {
  switch (action.type) {
    case "ADD_COUPON":
      return { ...state, coupons: [action.payload, ...state.coupons] };
    case "SET_COUPONS":
      return { ...state, coupons: action.payload };
    case "INIT_LOCATION_LIST":
      return { ...state, locations: action.payload };
    case "INIT_APPOINTMENT_LIST":
      return { ...state, appointments: action.payload };
    case "INIT_CLIENT_LIST":
      return { ...state, clients: action.payload };
    case "ADD_CLIENT_TO_LIST":
      return {
        ...state,
        clients: [...state.clients, action.payload],
      };
    case "INIT_STAFF_LIST":
      return { ...state, staff: action.payload };
    case "ADD_STAFF_TO_LIST":
      return {
        ...state,
        staff: [action.payload, ...state.staff],
      };
    case "NEW_CLIENT_FORM":
      return {
        ...state,
        newClientForm: {
          ...state.newClientForm,
          [action.fieldName]: action.payload,
        },
      };
    case "RESET_CLIENT_FORM":
      return {
        ...state,
        newClientForm: {},
      };
    case "NEW_APPOINTMENT_FORM":
      return {
        ...state,
        newAppointmentForm: {
          ...state.newAppointmentForm,
          [action.fieldName]: action.payload,
        },
      };
    case "RESET_APPOINTMENT_FORM":
      return { ...state, newAppointmentForm: {} };
    case "NEW_EMPLOYEE_FORM":
      return {
        ...state,
        newEmployeeForm: {
          ...state.newEmployeeForm,
          [action.fieldName]: action.payload,
        },
      };
    case "RESET_EMPLOYEE_FORM":
      return { ...state, newEmployeeForm: {} };
    default:
      return state;
  }
};

export default Reducer;
