import React from "react";
import styled from "styled-components";

const Controlls = styled.div`
  width: 100%;
  display: flex;
  padding: 0.75rem;
  .MuiButtonBase-root,
  .MuiInputBase-root {
    margin: 0 0.5rem;
  }
`;

export default function ControllSection({ children }) {
  return <Controlls>{children}</Controlls>;
}
