import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../state";
import Navbar from "../../components/navbar";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import styled from "styled-components";
import useGetClients from "../../hooks/useGetClients";
import { EmailRounded, PhoneRounded } from "@material-ui/icons";
import useValidateUser from "../../hooks/useValidateUser";
import NewClientOverlay from "../../components/newClientOverlay";
import apiRequest from "../../api";

const Main = styled(Grid)``;

const ContentSection = styled(Grid)`
  padding: 0;
  margin: 0;
  width: 100%;
  flex-direction: column;
`;

const SectionHeading = styled.div`
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.02);
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const ControlSection = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const ClientTable = styled.div`
  width: 100%;
  .table_row {
    width: 100%;
    padding: 10px 0;
    display: flex;
    background: rgba(var(--Swyft-Purple));
    .table_row_unit {
      padding: 10px 5px;
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .table_row:nth-child(even) {
    background: rgba(var(--Swyft-Purple), 0.9);
  }
`;

export default function Clients() {
  useGetClients();
  const history = useHistory();
  const { loading, isAuth_ed } = useValidateUser();
  const [state, dispatch] = useContext(Context);
  const [isNewClientOverlayOpen, setNewClientOverlayOpen] = useState(false);
  const [clientDisplay, setClientDisplay] = useState([]);
  const [clientSearchTerm, setClientSearchTerm] = useState("");

  const openNewClientOverlay = () => setNewClientOverlayOpen(true);

  const searchClient = (e) => {
    setClientSearchTerm(e.target.value);

    setClientDisplay(
      state.clients.filter((client) => {
        return Object.values(client).some((val) =>
          String(val).toLowerCase().includes(e.target.value.toLowerCase())
        );
      })
    );
  };

  useEffect(() => {
    setClientDisplay(state.clients);
  }, [state.clients]);

  useEffect(() => {
    if (!loading && !isAuth_ed) return history.push("/");
  }, [loading]);

  return (
    <Main container item xs={12}>
      <Navbar />
      <ContentSection container item xs={10}>
        <NewClientOverlay
          clientDisplay={clientDisplay}
          setClientDisplay={setClientDisplay}
          isOverlayOpen={isNewClientOverlayOpen}
          setOverlayOpen={setNewClientOverlayOpen}
        />
        <SectionHeading>
          <Typography variant="h4">Clients</Typography>
        </SectionHeading>

        <ControlSection>
          <Button onClick={openNewClientOverlay}>
            <Typography>New Client +</Typography>
          </Button>
          <div className="client_search_container">
            <TextField
              variant="outlined"
              placeholder="Search Clients"
              value={clientSearchTerm}
              onChange={searchClient}
            />
          </div>
        </ControlSection>

        <ClientTable>
          <div className="table_row">
            <div className="table_row_unit">
              <Typography>Client Name</Typography>
            </div>
            <div className="table_row_unit">
              <Typography>Phone Number</Typography>
            </div>
            <div className="table_row_unit">
              <Typography>Email Address</Typography>
            </div>
            <div className="table_row_unit">
              <Typography>Tools</Typography>
            </div>
          </div>
          {clientDisplay.map((client) => (
            <div
              className="table_row"
              onClick={() => history.push(`/dashboard/clients/${client._id}`)}
              key={client._id}
            >
              <div className="table_row_unit">
                <Typography>
                  {client.firstName
                    ? `${client.firstName} ${client.lastName}`
                    : ""}
                </Typography>
              </div>
              <div className="table_row_unit">
                <Typography>{client.phoneNumber}</Typography>
              </div>
              <div className="table_row_unit">
                <Typography>{client.email}</Typography>
              </div>
              <div className="table_row_unit">
                <PersonPinCircleIcon />
                <EmailRounded />
                <PhoneRounded />
              </div>
            </div>
          ))}
        </ClientTable>
      </ContentSection>
    </Main>
  );
}
