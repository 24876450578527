import {
  Button,
  Dialog,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import apiRequest from "../../api";
import { Context } from "../../state";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const FlashError = styled(Alert)`
  margin: 20px 0 0;
`;

const Overlay = styled(Dialog)`
  .MuiPaper-root {
    padding: 10px;
    width: 100%;
    max-width: 800px;
  }
  .overlay-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }
`;

const NewAppointmentForm = styled.form`
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  .MuiAutocomplete-inputRoot {
    padding: 0px;
  }
  .MuiTextField-root {
    margin: 10px 0;
    width: 100%;
    input {
      height: 50px;
    }
  }
  .multi-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .MuiTextField-root {
      width: 49%;
    }
  }
  .vehicle-information-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .MuiTextField-root {
      margin: 10px 0;
      width: 32%;
    }
  }
  .save-and-pay-button {
    margin: 10px 0;
    height: 50px;
    background: rgba(var(--Swyft-Green));
    color: #fff;
  }
`;

export default function NewAppointmentOverlay(props) {
  const [state, dispatch] = useContext(Context);
  const { isOpen, setIsOpen, setIsPaymentOverlayOpen } = props;

  //Client Autocomplete State
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientInputValue, setClientInputValue] = useState("");

  //Vehicle Autocomplete State
  const [clientVehicles, setClientVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicleInputValue, setVehicleInputValue] = useState("");

  //Swyft Service Packages
  const [servicePackages, setServicePackages] = useState([]);
  const [selectedServicePackage, setSelectedServicePackage] = useState(null);
  const [servicePackageInputValue, setServicePackageInputValue] = useState("");

  //Flash Error State
  const [flashError, setFlashError] = useState(null);

  const { clients, newAppointmentForm } = state;

  const handleClientSelect = (option, value) => {
    setSelectedClient(value);
    if (!value) return;
    setClientInputValue(`${value.firstName} ${value.lastName}`);
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "_id",
      payload: value._id,
    });
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "firstName",
      payload: value.firstName,
    });
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "lastName",
      payload: value.lastName,
    });
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "email",
      payload: value.email,
    });
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "phoneNumber",
      payload: value.phoneNumber,
    });
    setClientVehicles(value.vehicles);
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: e.target.name,
      payload: e.target.value,
    });
  };

  const handleVehicleSelect = (option, value) => {
    setSelectedVehicle(value);
    if (!value) return;
    setVehicleInputValue(`${value.year} ${value.make} ${value.model}`);
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "vehicleYear",
      payload: value.year,
    });
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "vehicleMake",
      payload: value.make,
    });
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "vehicleModel",
      payload: value.model,
    });
  };

  const handleDateChange = (e) =>
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "dateTime",
      payload: e.toISOString(),
    });

  const getProducts = () =>
    setServicePackages([
      { name: "Swyft Green" },
      { name: "Swyft Blue" },
      { name: "Swyft Purple" },
    ]);

  const handleServiceSelection = (option, value) => {
    setSelectedServicePackage(value);
    setServicePackageInputValue(value.name);
    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "cart",
      payload: [value],
    });
  };

  const handleAptSubmit = async (e) => {
    e.preventDefault();

    if (!state.newAppointmentForm.firstName || !selectedClient)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please select a client.",
      });

    if (!state.newAppointmentForm.lastName)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please enter the clients last name.",
      });

    if (!state.newAppointmentForm.email)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please enter the clients email address.",
      });

    if (!state.newAppointmentForm.phoneNumber)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please enter the clients phone number.",
      });

    if (!state.newAppointmentForm.vehicleYear || !selectedVehicle)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please select the clients vehicle.",
      });

    if (!state.newAppointmentForm.vehicleMake)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please enter the clients vehicle make.",
      });

    if (!state.newAppointmentForm.vehicleModel)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please enter the clients vehicle model.",
      });

    if (
      !state.newAppointmentForm.cart ||
      state.newAppointmentForm.cart.length < 1
    )
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please select a service package that fits the client and their vehicle.",
      });

    if (!state.newAppointmentForm.address)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please enter the clients service address.",
      });

    if (!state.newAppointmentForm.dateTime)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please select an appointment date and time.",
      });

    if (!state.newAppointmentForm.timeWindow)
      return setFlashError({
        isError: true,
        title: "Form Error",
        msg: "Please select the clients preferred time window.",
      });

    setFlashError(null);

    const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

    const paymentIntentResponse = await apiRequest(
      "/stripe/paymentIntent/create",
      "POST",
      { Authorization: token },
      {
        cart: state.newAppointmentForm.cart,
        userId: selectedClient._id,
      }
    );

    // Add Flash Error Here
    if (!paymentIntentResponse.success) return null;

    dispatch({
      type: "NEW_APPOINTMENT_FORM",
      fieldName: "stripeInfo",
      payload: {
        clientSecret: paymentIntentResponse.payload.clientSecret,
        paymentIntentId: paymentIntentResponse.payload.paymentIntentId,
      },
    });
    setIsPaymentOverlayOpen(true);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Overlay open={isOpen}>
      <div className="overlay-title-bar">
        <Typography variant="h4">New Appointment</Typography>
        <Close onClick={() => setIsOpen(false)} />
      </div>
      {flashError ? (
        <FlashError severity={flashError.isError ? "error" : "success"}>
          <AlertTitle>{flashError.title}</AlertTitle>
          <Typography>{flashError.msg}</Typography>
        </FlashError>
      ) : null}
      <NewAppointmentForm onSubmit={handleAptSubmit}>
        <Typography variant="h6">Client Information</Typography>
        <Autocomplete
          options={clients}
          value={selectedClient}
          onChange={handleClientSelect}
          inputValue={clientInputValue}
          onInputChange={(e) =>
            e ? setClientInputValue(e.target.value) : null
          }
          getOptionLabel={(client) =>
            `${client.firstName} ${client.lastName} ${client.phoneNumber} ${client.email}`
          }
          renderOption={(client) => `${client.firstName} ${client.lastName}`}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Please select a client."
              variant="outlined"
            />
          )}
        />
        <div className="multi-input-container">
          <TextField
            variant="outlined"
            label="First Name"
            name="firstName"
            value={newAppointmentForm.firstName || ""}
            onChange={handleInputChange}
            disabled={!newAppointmentForm.firstName}
          />
          <TextField
            variant="outlined"
            label="Last Name"
            name="lastName"
            value={newAppointmentForm.lastName || ""}
            onChange={handleInputChange}
            disabled={!newAppointmentForm.firstName}
          />
        </div>
        <div className="multi-input-container">
          <TextField
            variant="outlined"
            label="Email Address"
            name="email"
            value={newAppointmentForm.email || ""}
            onChange={handleInputChange}
            disabled={!newAppointmentForm.lastName}
          />
          <TextField
            variant="outlined"
            label="Phone Number"
            name="phoneNumber"
            value={newAppointmentForm.phoneNumber || ""}
            onChange={handleInputChange}
            disabled={!newAppointmentForm.email}
          />
        </div>
        <Typography variant="h6">Vehicle Information</Typography>
        <Autocomplete
          options={clientVehicles}
          value={selectedVehicle}
          onChange={handleVehicleSelect}
          inputValue={vehicleInputValue}
          onInputChange={(e) =>
            e ? setVehicleInputValue(e.target.value) : null
          }
          getOptionLabel={(vehicle) =>
            `${vehicle.year} ${vehicle.make} ${vehicle.model}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Please select a vehicle"
            />
          )}
        />
        <div className="vehicle-information-container">
          <TextField
            variant="outlined"
            label="Vehicle Year"
            name="vehicleYear"
            value={newAppointmentForm.vehicleYear || ""}
            onChange={handleInputChange}
            disabled={!newAppointmentForm.phoneNumber}
          />
          <TextField
            variant="outlined"
            label="Vehicle Make"
            name="vehicleMake"
            value={newAppointmentForm.vehicleMake || ""}
            onChange={handleInputChange}
          />
          <TextField
            variant="outlined"
            label="Vehicle Model"
            name="vehicleModel"
            value={newAppointmentForm.vehicleModel || ""}
            onChange={handleInputChange}
          />
        </div>
        <Typography variant="h6">Appointment Information</Typography>
        <Autocomplete
          options={servicePackages}
          value={selectedServicePackage}
          onChange={handleServiceSelection}
          inputValue={servicePackageInputValue}
          onInputChange={(e) =>
            e ? setServicePackageInputValue(e.target.value) : null
          }
          getOptionLabel={(product) => product.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Please select a service package"
            />
          )}
        />
        <TextField
          variant="outlined"
          label="Street Address"
          name="address"
          value={newAppointmentForm.address || ""}
          onChange={handleInputChange}
        />
        <div className="multi-input-container">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              inputVariant="outlined"
              value={newAppointmentForm.dateTime || Date.now()}
              onChange={handleDateChange}
              style={{ width: "49%" }}
            />
          </MuiPickersUtilsProvider>
          <Select
            variant="outlined"
            label="Time Window"
            placeholder="Time Window"
            displayEmpty
            style={{ width: "49%", height: "50px" }}
            name="timeWindow"
            value={newAppointmentForm.timeWindow || ""}
            onChange={handleInputChange}
          >
            <MenuItem value="" disabled>
              <Typography>Time Window</Typography>
            </MenuItem>
            <MenuItem value="8:00AM - 10:00AM">
              <Typography>8:00AM - 10:00AM</Typography>
            </MenuItem>
            <MenuItem value="10:00AM - 12:00PM">
              <Typography>10:00AM - 12:00PM</Typography>
            </MenuItem>
            <MenuItem value="12:00PM - 2:00PM">
              <Typography>12:00PM - 2:00PM</Typography>
            </MenuItem>
            <MenuItem value="2:00PM - 4:00PM">
              <Typography>2:00PM - 4:00PM</Typography>
            </MenuItem>
            <MenuItem value="4:00PM - 6:00PM">
              <Typography>4:00PM - 6:00PM</Typography>
            </MenuItem>
          </Select>
        </div>
        <Button
          variant="contained"
          className="save-and-pay-button "
          type="submit"
        >
          <Typography>Save & Pay Now</Typography>
        </Button>
      </NewAppointmentForm>
    </Overlay>
  );
}
