const serverIP = process.env.REACT_APP_API_URL

const apiRequest = async (uri, method, headers, body) => {
  const response = await fetch(`${serverIP}${uri}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  })
  const json = await response.json()

  return json
}

export default apiRequest
