import {
  Button,
  FormControlLabel,
  Input,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { UploadFile } from "@mui/icons-material";
import { Alert, AlertTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { addMonths } from "date-fns";

const Form = styled.form`
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  .MuiFormControl-root {
    margin: 0.5rem 0;
  }
  .form-img {
    display: flex;
    flex-direction: column;
    width: 33%;
    img {
      margin: 0.25rem 0;
      width: 100%;
      height: auto;
    }
  }
  .multi-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export default function DealForm({ data, id, cancelBtn }) {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    linkToTerms: "",
    ctaLink: "",
    ctaBtnText: "",
    startDate: Date.now(),
    endDate: addMonths(Date.now(), 3),
    thumbnailUrl: "",
    dealImgUrl: "",
    isActive: true,
    isVisable: true,
  });
  const [flashError, setFlashError] = useState(null);

  const handleFileChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });

  const toggleSwitch = (e) =>
    setFormData({ ...formData, [e.target.name]: !formData[e.target.name] });

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!formData.title) throw Error("Please enter a deal title.");

      if (!formData.description)
        throw Error("Please enter a deal description.");

      if (!formData.startDate)
        throw Error("Please select a start date and time for the deal.");

      if (!formData.endDate)
        throw Error("Please select a end date and time for the deal.");

      const requestData = new FormData();

      for (const [key, value] of Object.entries(formData)) {
        requestData.append(key, value);
      }

      const token = localStorage.getItem(process.env.REACT_APP_LOGIN_TOKEN);

      let dealResponse;

      id
        ? (dealResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/deals/${id}`,
            {
              method: "POST",
              headers: {
                Authorization: token,
              },
              body: requestData,
            }
          ).then((res) => res.json()))
        : (dealResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/deals`,
            {
              method: "POST",
              headers: {
                Authorization: token,
              },
              body: requestData,
            }
          ).then((res) => res.json()));

      if (!dealResponse.success) return;

      setFormData(dealResponse.payload.deal);

      alert(id ? "Deal updated successfully" : "Deal created successfully");

      return window.location.reload();
    } catch (error) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setFlashError(error.message);
    }
  };

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <Form onSubmit={handleSubmit}>
      {flashError ? (
        <Alert severity="error">
          <AlertTitle>Form Error</AlertTitle>
          {flashError}
        </Alert>
      ) : null}
      <TextField
        variant="outlined"
        placeholder="Title"
        label="Title"
        name="title"
        value={formData?.title || ""}
        onChange={handleChange}
      />
      <TextField
        variant="outlined"
        placeholder="Description"
        label="Descrtiption"
        name="description"
        multiline
        rows={5}
        value={formData?.description || ""}
        onChange={handleChange}
      />
      <TextField
        variant="outlined"
        placeholder="Link To Terms"
        label="Link To Terms"
        name="linkToTerms"
        value={formData?.linkToTerms || ""}
        onChange={handleChange}
      />
      <TextField
        variant="outlined"
        placeholder="Call To Action Link"
        label="Call To Action Link"
        name="ctaLink"
        value={formData?.ctaLink || ""}
        onChange={handleChange}
      />
      <TextField
        variant="outlined"
        placeholder="Call To Action Button Text"
        label="Call To Action Button Text"
        name="ctaBtnText"
        value={formData?.ctaBtnText || ""}
        onChange={handleChange}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          inputVariant="outlined"
          placeholder="Start Date"
          label="Start Date"
          name="startDate"
          value={formData?.startDate}
          onChange={(e) =>
            handleChange({
              target: { name: "startDate", value: e.toISOString() },
            })
          }
        />
        <DateTimePicker
          inputVariant="outlined"
          placeholder="End Date"
          label="End Date"
          name="endDate"
          value={formData?.endDate}
          onChange={(e) =>
            handleChange({
              target: { name: "endDate", value: e.toISOString() },
            })
          }
        />
      </MuiPickersUtilsProvider>
      <div className="multi-input">
        <FormControlLabel
          control={
            <Switch
              checked={formData?.isActive}
              name="isActive"
              onClick={toggleSwitch}
            />
          }
          label="Is Active"
        />
        <FormControlLabel
          control={
            <Switch
              checked={formData?.isVisable}
              name="isVisable"
              onClick={toggleSwitch}
            />
          }
          label="Is Visable"
        />
      </div>
      <div className="multi-input">
        <div className="form-img">
          <Typography variant="h6">
            Deal Thumbnail (Shown the deals page)
          </Typography>
          <img src={formData?.thumbnailUrl} />
          <label htmlFor="thumbnail-file">
            <Input
              accept="image/*"
              id="thumbnail-file"
              multiple
              type="file"
              name="thumbnail"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              variant="outlined"
              component="span"
              endIcon={<UploadFile />}
            >
              Upload Image
            </Button>
          </label>
        </div>
        <div className="form-img">
          <Typography variant="h6">
            Deal Image (Shown on a single deal page)
          </Typography>
          <img src={formData?.dealImgUrl} />
          <label htmlFor="dealImg-file">
            <Input
              accept="image/*"
              id="dealImg-file"
              multiple
              name="dealImg"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Button
              variant="outlined"
              component="span"
              endIcon={<UploadFile />}
            >
              Upload Image
            </Button>
          </label>
        </div>
      </div>
      <span style={{ margin: "1rem 0" }}>
        <Button type="submit" variant="outlined" endIcon={<Save />}>
          <Typography>{id ? "Update Deal" : "Create New Deal"}</Typography>
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          onClick={cancelBtn}
        >
          <Typography>Cancel</Typography>
        </Button>
      </span>
    </Form>
  );
}
