import { useState, useEffect } from 'react'

const useValidateUser = () => {
  const [loading, setLoading] = useState(true)
  const [isAuth_ed, setIsAuth_ed] = useState(false)

  const validateUser = async (token) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/validate`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    ).then((res) => res.json())

    if (response.success) setIsAuth_ed(true)

    if (!response.success)
      localStorage.removeItem(
        process.env.REACT_APP_LOGIN_TOKEN
      )

    return setLoading(false)
  }

  useEffect(() => {
    const token = localStorage.getItem(
      process.env.REACT_APP_LOGIN_TOKEN
    )

    if (!token) return setLoading(false)

    if (token) validateUser(token)
  }, [])

  return { loading, isAuth_ed }
}

export default useValidateUser
